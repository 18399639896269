import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { canDeleteUsers, canEditUserProfile, canEditUsers } from "../../../authentication/Permissions";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { ApiCallState, RequestState } from "../../../hooks/UseApiCall";
import {
    UpdateUserInput,
    useBlockUser,
    useDeleteUser,
    useResetPassword,
    useUnblockUser,
    useUpdateAvatar,
    useUpdateProfile,
} from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import { UserOutput } from "../../../openapi/backend";
import { getName } from "../../../utils.ts/GetName";
import { isUser } from "../../../utils.ts/Profile";
import { ConfirmationButton } from "../../core/ConfirmationButton/ConfirmationButton";
import TextButton from "../../core/TextButton/TextButton";
import { doNothing } from "../../../utils.ts/DoNothingCallback";
import Modal from "../../core/Modal/Modal";
import ProfileForm from "../../Profile/ProfileForm";

interface EditButtonsProps {
    subject: UserOutput;
    onDismiss: () => void;
    onError?: (error: Response) => void;
    refresh?: () => void;
}

function useRequestActionEffect<T>(
    request: ApiCallState<T>,
    onDismiss: EditButtonsProps["onDismiss"],
    onError: EditButtonsProps["onError"] = doNothing,
) {
    useEffect(() => {
        if (request.state === RequestState.DONE) {
            onDismiss();
        } else if (request.state === RequestState.ERROR) {
            onError(request.error);
        }
    }, [onDismiss, request, onError]);
}

const EditButtons = ({ subject, onDismiss, onError, refresh = doNothing }: EditButtonsProps) => {
    const [resetRequest, resetPassword] = useResetPassword();
    const [blockRequest, blockUser] = useBlockUser();
    const [unblockRequest, unblockUser] = useUnblockUser();
    const [deleteRequest, deleteUser] = useDeleteUser();
    const [updateRequest, updateUser] = useUpdateProfile();
    const [updateAvatarRequest, updateAvatar] = useUpdateAvatar();

    const { profile } = useContext(ProfileContext);
    const [profileModalOpen, setProfileModalOpen] = useState(false);
    const isSelf = useMemo(() => subject.id === profile?.id, [subject.id, profile?.id]);

    useRequestActionEffect(resetRequest, onDismiss, onError);
    useRequestActionEffect(blockRequest, onDismiss, onError);
    useRequestActionEffect(unblockRequest, onDismiss, onError);
    useRequestActionEffect(deleteRequest, onDismiss, onError);

    const resetPasswordClick = useCallback(() => resetPassword(subject.id), [resetPassword, subject.id]);
    const blockUserClick = useCallback(() => blockUser(subject.id), [blockUser, subject.id]);
    const unblockUserClick = useCallback(() => unblockUser(subject.id), [unblockUser, subject.id]);
    const deleteUserClick = useCallback(() => deleteUser(subject.id), [deleteUser, subject.id]);

    const isSubProfile = useMemo(() => !!subject.contactPerson, [subject.contactPerson]);

    // We need an additional wrapper because the react form sends an additional parameter which messes up things
    const updateProfile = useCallback(
        (input: UpdateUserInput) => {
            if (subject) {
                updateUser(input, subject.id);
            }
        },
        [updateUser, subject],
    );

    useEffect(() => {
        if (updateRequest.state === RequestState.DONE) {
            setProfileModalOpen(false);
            refresh();
        }
    }, [updateRequest.state, setProfileModalOpen, refresh]);

    useEffect(() => {
        if (updateAvatarRequest.state === RequestState.DONE) {
            refresh();
        }
    }, [updateAvatarRequest.state, refresh]);

    return isUser(subject) ? (
        <>
            {canEditUserProfile(profile, subject) && (
                <TextButton
                    onClick={() => setProfileModalOpen(true)}
                    text={strings.editProfile}
                    isLoading={resetRequest.state === RequestState.LOADING}
                    buttonType="alternate"
                />
            )}

            {canEditUsers(profile) && !isSubProfile && (
                <>
                    <TextButton
                        onClick={resetPasswordClick}
                        text={strings.resetPassword}
                        isLoading={resetRequest.state === RequestState.LOADING}
                        buttonType="alternate"
                    />
                    {!isSelf && !subject.isBlocked && (
                        <ConfirmationButton
                            onConfirm={blockUserClick}
                            text={strings.blockUser}
                            title={strings.confirmation}
                            body={strings.confirmBlockUser}
                            state={blockRequest.state}
                            buttonType="alternate"
                        />
                    )}
                    {!isSelf && subject.isBlocked && (
                        <TextButton
                            onClick={unblockUserClick}
                            text={strings.unblockUser}
                            isLoading={unblockRequest.state === RequestState.LOADING}
                            buttonType="alternate"
                        />
                    )}
                    {!isSelf && canDeleteUsers(profile) && (
                        <ConfirmationButton
                            onConfirm={deleteUserClick}
                            text={strings.deleteUser}
                            title={strings.confirmDeleteTitleUser}
                            body={strings.formatString(strings.confirmDeleteBodyUser, getName(subject)) as string}
                            state={blockRequest.state}
                            confirmLabel={strings.delete}
                            cancelLabel={strings.cancel}
                            buttonType="alternate"
                        />
                    )}
                </>
            )}

            {profile && canEditUserProfile(profile, subject) && (
                <Modal title={strings.profile} isOpen={profileModalOpen} onClose={() => setProfileModalOpen(false)}>
                    <ProfileForm
                        profile={subject}
                        updateProfile={updateProfile}
                        isSubmitting={[updateRequest.state, updateAvatarRequest.state].includes(RequestState.LOADING)}
                        updateAvatar={updateAvatar}
                        error={updateAvatarRequest.error || updateRequest.error}
                    />
                </Modal>
            )}
        </>
    ) : null;
};

export default EditButtons;
