import { FieldError, UseFormRegister } from "react-hook-form";
import strings from "../../../localization/strings";
import styles from "./ApplicationForm.module.scss";
import FormFileInput from "../../core/Form/FormFileInput/FormFileInput";
import { useContext, useMemo } from "react";
import { AttachmentsContext } from "../../../contexts/AttachmentsContext";
import FileList from "../../core/FileList/FileList";

interface AttachmentsFieldProps {
    register: UseFormRegister<any>;
}

export default function AttachmentsField({ register }: AttachmentsFieldProps) {
    const { file, selectedFile, setSelectedFile } = useContext(AttachmentsContext);

    const hasFile = useMemo(() => !!file || !!selectedFile, [file, selectedFile]);

    return (
        <div className={styles.section}>
            <h2 className={styles.sectionHeader}>{strings.attachments}</h2>
            {!hasFile ? (
                <FormFileInput
                    register={register}
                    errors={!hasFile ? ({ type: "pattern", message: strings.mandatoryField } as FieldError) : undefined}
                    name={"files"}
                    onChange={setSelectedFile}
                    label={strings.add}
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                />
            ) : (
                <FileList className={styles.thumbnail} />
            )}
        </div>
    );
}
