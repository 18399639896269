import LazyImage from "../LazyImage/LazyImage";
import styles from "./FileList.module.scss";
import TrashIcon from "../../../assets/remove_icon_white.svg";
import strings from "../../../localization/strings";
import { useCallback, useContext, useState } from "react";
import { AttachmentsContext } from "../../../contexts/AttachmentsContext";
import FileDeleteConfirmationModal from "../FileDeleteConfirmationModal/FileDeleteConfirmationModal";
import fileDownload from "js-file-download";

function renderFileIcon(fileName: string, downloadFile: () => void, deleteFile: () => void) {
    return (
        <div>
            <div className={styles.fileContainer}>
                <button className={styles.item} type="button" onClick={downloadFile}>
                    <div className={styles.fileButton}>
                        <div className={styles.fileExtension}>{`.${fileName.split(".").pop()?.toUpperCase()}`}</div>
                    </div>
                </button>
                <button className={styles.deleteButton} type="button" onClick={deleteFile}>
                    <LazyImage
                        className={styles.image}
                        width="100%"
                        height="100%"
                        src={TrashIcon}
                        alt={strings.delete}
                        draggable={false}
                    />
                </button>
            </div>
            <div className={styles.fileName}>{fileName}</div>
        </div>
    );
}

type Props = {
    className?: string;
};

export default function FileList({ className }: Props) {
    const { file, selectedFile, setSelectedFile, setExistingFile, downloadFile } = useContext(AttachmentsContext);

    const [showConfirm, setShowConfirm] = useState(false);
    const doShowConfirm = useCallback(() => setShowConfirm(true), []);
    const doHideConfirm = useCallback(() => setShowConfirm(false), []);

    const [showConfirmSelection, setShowConfirmSelection] = useState(false);
    const doShowConfirmSelection = useCallback(() => setShowConfirmSelection(true), []);
    const doHideConfirmSelection = useCallback(() => setShowConfirmSelection(false), []);

    const onDelete = useCallback(() => {
        setExistingFile(undefined);
        doHideConfirm();
    }, [setExistingFile, doHideConfirm]);

    const onDeleteSelection = useCallback(() => {
        setSelectedFile(undefined);
        doHideConfirmSelection();
    }, [setSelectedFile, doHideConfirmSelection]);

    const downloadSelectedFile = useCallback(() => {
        if (selectedFile) {
            fileDownload(selectedFile, selectedFile.name);
        }
    }, [selectedFile]);

    return (
        <div className={className}>
            {file &&
                renderFileIcon(file.orginalFileName, () => downloadFile(file.id, file.orginalFileName), doShowConfirm)}
            {selectedFile && renderFileIcon(selectedFile.name, downloadSelectedFile, doShowConfirmSelection)}

            {file && (
                <FileDeleteConfirmationModal
                    message={
                        strings.formatString(strings.confirmDeleteFile, file.orginalFileName || file.fileName) as string
                    }
                    show={showConfirm}
                    onClose={doHideConfirm}
                    onDelete={onDelete}
                />
            )}

            {selectedFile && (
                <FileDeleteConfirmationModal
                    message={strings.formatString(strings.confirmDeleteFile, selectedFile.name) as string}
                    show={showConfirmSelection}
                    onClose={doHideConfirmSelection}
                    onDelete={onDeleteSelection}
                />
            )}
        </div>
    );
}
