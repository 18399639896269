/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    NotificationChannel,
    NotificationChannelFromJSON,
    NotificationChannelFromJSONTyped,
    NotificationChannelToJSON,
    NotificationFilterViewModel,
    NotificationFilterViewModelFromJSON,
    NotificationFilterViewModelFromJSONTyped,
    NotificationFilterViewModelToJSON,
    ProfileOutput,
    ProfileOutputFromJSON,
    ProfileOutputFromJSONTyped,
    ProfileOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface NotificationViewModel
 */
export interface NotificationViewModel {
    /**
     *
     * @type {Date}
     * @memberof NotificationViewModel
     */
    createdOn: Date;
    /**
     *
     * @type {NotificationChannel}
     * @memberof NotificationViewModel
     */
    channel: NotificationChannel;
    /**
     *
     * @type {string}
     * @memberof NotificationViewModel
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof NotificationViewModel
     */
    body: string;
    /**
     *
     * @type {NotificationFilterViewModel}
     * @memberof NotificationViewModel
     */
    to?: NotificationFilterViewModel;
    /**
     *
     * @type {ProfileOutput}
     * @memberof NotificationViewModel
     */
    by?: ProfileOutput;
}

/**
 * Check if a given object implements the NotificationViewModel interface.
 */
export function instanceOfNotificationViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdOn" in value;
    isInstance = isInstance && "channel" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function NotificationViewModelFromJSON(json: any): NotificationViewModel {
    return NotificationViewModelFromJSONTyped(json, false);
}

export function NotificationViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        createdOn: !exists(json, "createdOn") ? (undefined as any) : new Date(json["createdOn"]),
        channel: !exists(json, "channel") ? (undefined as any) : NotificationChannelFromJSON(json["channel"]),
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        body: !exists(json, "body") ? (undefined as any) : json["body"],
        to: !exists(json, "to") ? (undefined as any) : NotificationFilterViewModelFromJSON(json["to"]),
        by: !exists(json, "by") ? (undefined as any) : ProfileOutputFromJSON(json["by"]),
    };
}

export function NotificationViewModelToJSON(value?: NotificationViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
