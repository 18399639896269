import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { canAssignApplications } from "../../../authentication/Permissions";
import { AttachmentsContext } from "../../../contexts/AttachmentsContext";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { useCreateApplicationWithFileUpload, useGetFlatAuthorizedArrangements } from "../../../hooks/ArrangementHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import { useGetIntermediaries } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import { ArrangementListOutput } from "../../../openapi/backend";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import SelectField from "../../core/Inputs/SelectField/SelectField";
import ApplicationForm from "./ApplicationForm";

export default function CreateApplicationContainer() {
    const { value: arrangements } = useGetFlatAuthorizedArrangements();
    const { value: intermediaries } = useGetIntermediaries();
    const { selectedFile } = useContext(AttachmentsContext);
    const { profile } = useContext(ProfileContext);
    const internalArrangements = useMemo(
        () => arrangements?.filter((arrangement) => !!arrangement.form) ?? [],
        [arrangements],
    );
    const options = useMemo(
        () => internalArrangements.map((arrangement) => ({ label: arrangement.name, value: arrangement.id })),
        [internalArrangements],
    );
    const [chosen, setChosen] = useState<ArrangementListOutput>();
    const props = useMemo(
        () =>
            chosen
                ? {
                      application: { type: chosen.form, reviewerId: profile?.id } as any,
                      header: { arrangement: chosen },
                  }
                : undefined,
        [chosen, profile?.id],
    );

    const {
        requestState: { state: createState, error },
        callback: createApplication,
    } = useCreateApplicationWithFileUpload();
    const onSave = useCallback(
        (input) => chosen && createApplication(chosen.id, input, selectedFile),
        [createApplication, chosen, selectedFile],
    );
    const navigate = useNavigate();
    const dismiss = useCallback(
        () => navigate(canAssignApplications(profile) ? "/applications/all" : "/applications/my", { replace: true }),
        [navigate, profile],
    );

    useEffect(() => {
        if (createState === RequestState.DONE) {
            dismiss();
        }
    }, [dismiss, createState]);

    if (internalArrangements.length <= 0 || !profile) {
        return <InlineLoading />;
    }

    return (
        <div>
            <SelectField
                name="arrangement"
                options={options}
                transform={(value) => value}
                onChange={(value) => setChosen(internalArrangements.find((arrangement) => arrangement.id === value))}
                value={chosen?.id}
                placeholder={strings.chooseArrangement}
            />
            {props && (
                <ApplicationForm
                    key={chosen?.form}
                    application={props.application}
                    onDismiss={dismiss}
                    intermediaries={intermediaries}
                    onPatch={onSave}
                    header={props.header}
                    isLoading={createState === RequestState.LOADING}
                    requestError={error}
                />
            )}
        </div>
    );
}
