import { usePartnerSuggestionsOverview } from "../../hooks/HappeningsHooks";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["states"];

export default function PartnerSuggestionsContainer() {
    const [happeningsState, getHappenings] = usePartnerSuggestionsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
        />
    );
}
