import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import { useApplicationDetails, usePatchApplicationWithFileUpload } from "../../../hooks/ArrangementHooks";
import { useGetIntermediaries } from "../../../hooks/UserHooks";
import { PatchFunction } from "./SharedApplicationTypes";
import { createPatch } from "rfc6902";
import { hasValues } from "../../../utils.ts/Array";
import { Apply200Response } from "../../../openapi/backend";
import { EditApplicationContext } from "../../../contexts/EditApplicationContext";
import { toastSuccess } from "../../../utils.ts/Toaster";
import ApplicationForm from "./ApplicationForm";
import { AttachmentsContext } from "../../../contexts/AttachmentsContext";

type JsonPatchWorkaroundValues = { message: string };

export default function ApplicationFormContainer() {
    const { applicationId } = useParams();
    const [application, setApplication] = useContext(EditApplicationContext);
    const { selectedFile } = useContext(AttachmentsContext);
    const { value: fetchedApplication, state: applicationState, error } = useApplicationDetails(applicationId || "");
    const {
        requestState: { value: updatedApplication, state: patchState, error: patchError },
        callback: patchCallback,
    } = usePatchApplicationWithFileUpload(applicationId || "");
    const { value: intermediaries } = useGetIntermediaries();

    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const onPatch = useCallback<PatchFunction>(
        (data, options) => {
            if (!application) {
                return;
            }

            // Set values the same, so they will be skipped in createPatch
            const modifications = {
                status: application.status,
                reviewer: application.reviewer,
                events: application.events,
                file: application.file,
            } as Partial<Apply200Response & JsonPatchWorkaroundValues>;

            if (options?.nextStatus) {
                modifications.status = options.nextStatus;
            }
            if (options?.message) {
                modifications.message = options.message;
            }

            const operations = createPatch(
                { ...application, reviewerId: application.reviewer?.id },
                { ...data, ...modifications },
                (input, output, ptr) => {
                    if (input instanceof Date && output instanceof Date && input.valueOf() !== output.valueOf()) {
                        return [{ op: "replace", path: ptr.toString(), value: output }];
                    }
                },
            );

            if (hasValues(operations) || selectedFile) {
                patchCallback(operations, selectedFile);
            }
        },
        [patchCallback, application, selectedFile],
    );

    useEffect(() => {
        if (fetchedApplication) {
            setApplication(fetchedApplication);
        }
    }, [fetchedApplication, setApplication]);

    useEffect(() => {
        if (updatedApplication) {
            setApplication(updatedApplication);
        }
    }, [updatedApplication, setApplication]);

    useEffect(() => {
        if (patchState === RequestState.DONE) {
            toastSuccess(strings.patchSuccess);
        }
    }, [patchState]);

    if (applicationState === RequestState.LOADING || applicationState === RequestState.IDLE) {
        return <div>{strings.loading}</div>;
    }

    if (applicationState === RequestState.ERROR) {
        return <ErrorMessage error={error!} />;
    }

    if (!application) {
        return <div>{strings.applicationNotFound}</div>;
    }

    return (
        <ApplicationForm
            onDismiss={goBack}
            application={{ ...application, reviewerId: application.reviewer?.id }}
            intermediaries={intermediaries}
            isLoading={patchState === RequestState.LOADING}
            onPatch={onPatch}
            header={application}
            status={application.status}
            events={application.events}
            requestError={patchError}
        />
    );
}
