/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    CountOutput,
    CountOutputFromJSON,
    CountOutputToJSON,
    Gender,
    GenderFromJSON,
    GenderToJSON,
    HappeningNotificationGroup,
    HappeningNotificationGroupFromJSON,
    HappeningNotificationGroupToJSON,
    NotificationChannel,
    NotificationChannelFromJSON,
    NotificationChannelToJSON,
    NotificationGroup,
    NotificationGroupFromJSON,
    NotificationGroupToJSON,
    NotificationSettings,
    NotificationSettingsFromJSON,
    NotificationSettingsToJSON,
    NotificationViewModelPaginatedViewModel,
    NotificationViewModelPaginatedViewModelFromJSON,
    NotificationViewModelPaginatedViewModelToJSON,
    QueuedOutput,
    QueuedOutputFromJSON,
    QueuedOutputToJSON,
    UserNotificationViewModelPaginatedViewModel,
    UserNotificationViewModelPaginatedViewModelFromJSON,
    UserNotificationViewModelPaginatedViewModelToJSON,
} from "../models";

export interface GeneralNotificationAudienceCountRequest {
    audience: NotificationGroup;
    channel: NotificationChannel;
    version: string;
    minAge?: number;
    maxAge?: number;
    genders?: Array<Gender>;
    happeningTypeTags?: Array<string>;
    locationTags?: Array<string>;
    partnerTags?: Array<string>;
    happeningGroupTags?: Array<string>;
    serviceGroupTags?: Array<string>;
    targetGroupTags?: Array<string>;
    otherTags?: Array<string>;
}

export interface GetHappeningNotificationsRequest {
    id: string;
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
}

export interface GetMyPushNotificationsRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
}

export interface GetMyUnreadPushNotificationsCountRequest {
    version: string;
}

export interface GetNotificationsRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
}

export interface HappeningNotificationAudienceCountRequest {
    happeningId: string;
    groups: Array<HappeningNotificationGroup>;
    channel: NotificationChannel;
    version: string;
    sessions?: Array<number>;
}

export interface ReadMyUserNotificationsRequest {
    notificationId: number;
    version: string;
}

export interface SendGeneralNotificationRequest {
    version: string;
    generalFilterOptionsAudience: NotificationGroup;
    generalFilterOptionsChannel: NotificationChannel;
    title: string;
    body: string;
    generalFilterOptionsMinAge?: number;
    generalFilterOptionsMaxAge?: number;
    generalFilterOptionsGenders?: Array<Gender>;
    generalFilterOptionsHappeningTypeTags?: Array<string>;
    generalFilterOptionsLocationTags?: Array<string>;
    generalFilterOptionsPartnerTags?: Array<string>;
    generalFilterOptionsHappeningGroupTags?: Array<string>;
    generalFilterOptionsServiceGroupTags?: Array<string>;
    generalFilterOptionsTargetGroupTags?: Array<string>;
    generalFilterOptionsOtherTags?: Array<string>;
    link?: string;
}

export interface SendHappeningNotificationRequest {
    version: string;
    happeningFilterOptionsHappeningId: string;
    happeningFilterOptionsGroups: Array<HappeningNotificationGroup>;
    happeningFilterOptionsChannel: NotificationChannel;
    title: string;
    body: string;
    happeningFilterOptionsSessions?: Array<number>;
    link?: string;
}

export interface SetNotificationSettingsRequest {
    version: string;
    notificationSettings?: NotificationSettings;
}

/**
 * no description
 */
export class NotificationsApi extends runtime.BaseAPI {
    /**
     */
    async generalNotificationAudienceCountRaw(
        requestParameters: GeneralNotificationAudienceCountRequest,
    ): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.audience === null || requestParameters.audience === undefined) {
            throw new runtime.RequiredError(
                "audience",
                "Required parameter requestParameters.audience was null or undefined when calling generalNotificationAudienceCount.",
            );
        }
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError(
                "channel",
                "Required parameter requestParameters.channel was null or undefined when calling generalNotificationAudienceCount.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling generalNotificationAudienceCount.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.audience !== undefined) {
            queryParameters["Audience"] = requestParameters.audience;
        }
        if (requestParameters.minAge !== undefined) {
            queryParameters["MinAge"] = requestParameters.minAge;
        }
        if (requestParameters.maxAge !== undefined) {
            queryParameters["MaxAge"] = requestParameters.maxAge;
        }
        if (requestParameters.genders && requestParameters.genders.length > 0) {
            queryParameters["Genders"] = requestParameters.genders;
        }
        if (requestParameters.happeningTypeTags && requestParameters.happeningTypeTags.length > 0) {
            queryParameters["HappeningTypeTags"] = requestParameters.happeningTypeTags;
        }
        if (requestParameters.locationTags && requestParameters.locationTags.length > 0) {
            queryParameters["LocationTags"] = requestParameters.locationTags;
        }
        if (requestParameters.partnerTags && requestParameters.partnerTags.length > 0) {
            queryParameters["PartnerTags"] = requestParameters.partnerTags;
        }
        if (requestParameters.happeningGroupTags && requestParameters.happeningGroupTags.length > 0) {
            queryParameters["HappeningGroupTags"] = requestParameters.happeningGroupTags;
        }
        if (requestParameters.serviceGroupTags && requestParameters.serviceGroupTags.length > 0) {
            queryParameters["ServiceGroupTags"] = requestParameters.serviceGroupTags;
        }
        if (requestParameters.targetGroupTags && requestParameters.targetGroupTags.length > 0) {
            queryParameters["TargetGroupTags"] = requestParameters.targetGroupTags;
        }
        if (requestParameters.otherTags && requestParameters.otherTags.length > 0) {
            queryParameters["OtherTags"] = requestParameters.otherTags;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters["Channel"] = requestParameters.channel;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/generalNotificationAudienceCount`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async generalNotificationAudienceCount(
        requestParameters: GeneralNotificationAudienceCountRequest,
    ): Promise<number> {
        const response = await this.generalNotificationAudienceCountRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getHappeningNotificationsRaw(
        requestParameters: GetHappeningNotificationsRequest,
    ): Promise<runtime.ApiResponse<NotificationViewModelPaginatedViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getHappeningNotifications.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getHappeningNotifications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/UserNotifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            NotificationViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getHappeningNotifications(
        requestParameters: GetHappeningNotificationsRequest,
    ): Promise<NotificationViewModelPaginatedViewModel> {
        const response = await this.getHappeningNotificationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyPushNotificationsRaw(
        requestParameters: GetMyPushNotificationsRequest,
    ): Promise<runtime.ApiResponse<UserNotificationViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyPushNotifications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/userNotifications/my/push`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserNotificationViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getMyPushNotifications(
        requestParameters: GetMyPushNotificationsRequest,
    ): Promise<UserNotificationViewModelPaginatedViewModel> {
        const response = await this.getMyPushNotificationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyUnreadPushNotificationsCountRaw(
        requestParameters: GetMyUnreadPushNotificationsCountRequest,
    ): Promise<runtime.ApiResponse<CountOutput>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyUnreadPushNotificationsCount.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/userNotifications/my/push/unread/count`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => CountOutputFromJSON(jsonValue));
    }
    /**
     */
    async getMyUnreadPushNotificationsCount(
        requestParameters: GetMyUnreadPushNotificationsCountRequest,
    ): Promise<CountOutput> {
        const response = await this.getMyUnreadPushNotificationsCountRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getNotificationsRaw(
        requestParameters: GetNotificationsRequest,
    ): Promise<runtime.ApiResponse<NotificationViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getNotifications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/userNotifications`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            NotificationViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getNotifications(
        requestParameters: GetNotificationsRequest,
    ): Promise<NotificationViewModelPaginatedViewModel> {
        const response = await this.getNotificationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async happeningNotificationAudienceCountRaw(
        requestParameters: HappeningNotificationAudienceCountRequest,
    ): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling happeningNotificationAudienceCount.",
            );
        }
        if (requestParameters.groups === null || requestParameters.groups === undefined) {
            throw new runtime.RequiredError(
                "groups",
                "Required parameter requestParameters.groups was null or undefined when calling happeningNotificationAudienceCount.",
            );
        }
        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError(
                "channel",
                "Required parameter requestParameters.channel was null or undefined when calling happeningNotificationAudienceCount.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling happeningNotificationAudienceCount.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.happeningId !== undefined) {
            queryParameters["HappeningId"] = requestParameters.happeningId;
        }
        if (requestParameters.groups && requestParameters.groups.length > 0) {
            queryParameters["Groups"] = requestParameters.groups;
        }
        if (requestParameters.sessions && requestParameters.sessions.length > 0) {
            queryParameters["Sessions"] = requestParameters.sessions;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters["Channel"] = requestParameters.channel;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/happeningNotificationAudienceCount`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async happeningNotificationAudienceCount(
        requestParameters: HappeningNotificationAudienceCountRequest,
    ): Promise<number> {
        const response = await this.happeningNotificationAudienceCountRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async readMyUserNotificationsRaw(
        requestParameters: ReadMyUserNotificationsRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError(
                "notificationId",
                "Required parameter requestParameters.notificationId was null or undefined when calling readMyUserNotifications.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling readMyUserNotifications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Notifications/userNotifications/my/{notificationId}/read`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async readMyUserNotifications(requestParameters: ReadMyUserNotificationsRequest): Promise<void> {
        await this.readMyUserNotificationsRaw(requestParameters);
    }
    /**
     */
    async sendGeneralNotificationRaw(
        requestParameters: SendGeneralNotificationRequest,
    ): Promise<runtime.ApiResponse<QueuedOutput>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling sendGeneralNotification.",
            );
        }
        if (
            requestParameters.generalFilterOptionsAudience === null ||
            requestParameters.generalFilterOptionsAudience === undefined
        ) {
            throw new runtime.RequiredError(
                "generalFilterOptionsAudience",
                "Required parameter requestParameters.generalFilterOptionsAudience was null or undefined when calling sendGeneralNotification.",
            );
        }
        if (
            requestParameters.generalFilterOptionsChannel === null ||
            requestParameters.generalFilterOptionsChannel === undefined
        ) {
            throw new runtime.RequiredError(
                "generalFilterOptionsChannel",
                "Required parameter requestParameters.generalFilterOptionsChannel was null or undefined when calling sendGeneralNotification.",
            );
        }
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError(
                "title",
                "Required parameter requestParameters.title was null or undefined when calling sendGeneralNotification.",
            );
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling sendGeneralNotification.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.generalFilterOptionsAudience !== undefined) {
            formParams.append("GeneralFilterOptions.Audience", requestParameters.generalFilterOptionsAudience as any);
            hasParams = true;
        }
        if (requestParameters.generalFilterOptionsMinAge !== undefined) {
            formParams.append("GeneralFilterOptions.MinAge", requestParameters.generalFilterOptionsMinAge as any);
            hasParams = true;
        }
        if (requestParameters.generalFilterOptionsMaxAge !== undefined) {
            formParams.append("GeneralFilterOptions.MaxAge", requestParameters.generalFilterOptionsMaxAge as any);
            hasParams = true;
        }
        if (requestParameters.generalFilterOptionsGenders) {
            requestParameters.generalFilterOptionsGenders.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.Genders[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsHappeningTypeTags) {
            requestParameters.generalFilterOptionsHappeningTypeTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.HappeningTypeTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsLocationTags) {
            requestParameters.generalFilterOptionsLocationTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.LocationTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsPartnerTags) {
            requestParameters.generalFilterOptionsPartnerTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.PartnerTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsHappeningGroupTags) {
            requestParameters.generalFilterOptionsHappeningGroupTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.HappeningGroupTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsServiceGroupTags) {
            requestParameters.generalFilterOptionsServiceGroupTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.ServiceGroupTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsTargetGroupTags) {
            requestParameters.generalFilterOptionsTargetGroupTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.TargetGroupTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsOtherTags) {
            requestParameters.generalFilterOptionsOtherTags.forEach((element, index) => {
                appendToParams(formParams, `GeneralFilterOptions.OtherTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.generalFilterOptionsChannel !== undefined) {
            formParams.append("GeneralFilterOptions.Channel", requestParameters.generalFilterOptionsChannel as any);
            hasParams = true;
        }
        if (requestParameters.title !== undefined) {
            formParams.append("Title", requestParameters.title as any);
            hasParams = true;
        }
        if (requestParameters.body !== undefined) {
            formParams.append("Body", requestParameters.body as any);
            hasParams = true;
        }
        if (requestParameters.link !== undefined) {
            formParams.append("Link", requestParameters.link as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Notifications/sendGeneralNotification`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => QueuedOutputFromJSON(jsonValue));
    }
    /**
     */
    async sendGeneralNotification(requestParameters: SendGeneralNotificationRequest): Promise<QueuedOutput> {
        const response = await this.sendGeneralNotificationRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async sendHappeningNotificationRaw(
        requestParameters: SendHappeningNotificationRequest,
    ): Promise<runtime.ApiResponse<QueuedOutput>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling sendHappeningNotification.",
            );
        }
        if (
            requestParameters.happeningFilterOptionsHappeningId === null ||
            requestParameters.happeningFilterOptionsHappeningId === undefined
        ) {
            throw new runtime.RequiredError(
                "happeningFilterOptionsHappeningId",
                "Required parameter requestParameters.happeningFilterOptionsHappeningId was null or undefined when calling sendHappeningNotification.",
            );
        }
        if (
            requestParameters.happeningFilterOptionsGroups === null ||
            requestParameters.happeningFilterOptionsGroups === undefined
        ) {
            throw new runtime.RequiredError(
                "happeningFilterOptionsGroups",
                "Required parameter requestParameters.happeningFilterOptionsGroups was null or undefined when calling sendHappeningNotification.",
            );
        }
        if (
            requestParameters.happeningFilterOptionsChannel === null ||
            requestParameters.happeningFilterOptionsChannel === undefined
        ) {
            throw new runtime.RequiredError(
                "happeningFilterOptionsChannel",
                "Required parameter requestParameters.happeningFilterOptionsChannel was null or undefined when calling sendHappeningNotification.",
            );
        }
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError(
                "title",
                "Required parameter requestParameters.title was null or undefined when calling sendHappeningNotification.",
            );
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError(
                "body",
                "Required parameter requestParameters.body was null or undefined when calling sendHappeningNotification.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.happeningFilterOptionsHappeningId !== undefined) {
            formParams.append(
                "HappeningFilterOptions.HappeningId",
                requestParameters.happeningFilterOptionsHappeningId as any,
            );
            hasParams = true;
        }
        if (requestParameters.happeningFilterOptionsGroups) {
            requestParameters.happeningFilterOptionsGroups.forEach((element, index) => {
                appendToParams(formParams, `HappeningFilterOptions.Groups[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningFilterOptionsSessions) {
            requestParameters.happeningFilterOptionsSessions.forEach((element, index) => {
                appendToParams(formParams, `HappeningFilterOptions.Sessions[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningFilterOptionsChannel !== undefined) {
            formParams.append("HappeningFilterOptions.Channel", requestParameters.happeningFilterOptionsChannel as any);
            hasParams = true;
        }
        if (requestParameters.title !== undefined) {
            formParams.append("Title", requestParameters.title as any);
            hasParams = true;
        }
        if (requestParameters.body !== undefined) {
            formParams.append("Body", requestParameters.body as any);
            hasParams = true;
        }
        if (requestParameters.link !== undefined) {
            formParams.append("Link", requestParameters.link as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Notifications/sendHappeningNotification`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => QueuedOutputFromJSON(jsonValue));
    }
    /**
     */
    async sendHappeningNotification(requestParameters: SendHappeningNotificationRequest): Promise<QueuedOutput> {
        const response = await this.sendHappeningNotificationRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async setNotificationSettingsRaw(
        requestParameters: SetNotificationSettingsRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling setNotificationSettings.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Notifications/setNotificationSettings`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsToJSON(requestParameters.notificationSettings),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async setNotificationSettings(requestParameters: SetNotificationSettingsRequest): Promise<void> {
        await this.setNotificationSettingsRaw(requestParameters);
    }
}
