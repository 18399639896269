import { useCallback, useContext, useMemo } from "react";
import { TagsContext } from "../../../contexts/TagsContext";
import { HappeningFilter } from "../../../hooks/HappeningsHooks";
import { useOrganiserOptions } from "../../../hooks/TagHooks";
import { useProjectLeaderOptions } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import { TagViewModel } from "../../../openapi/backend";
import { HappeningStateOption, SimplifiedTargetGroupOption } from "../../../types/DropdownOption";
import { HappeningFilterKeys } from "../../../types/HappeningFilterKeys";
import { UserNamedType } from "../../../types/UserNamedType";
import { statusToString } from "../../../utils.ts/GetStatus";
import { getName } from "../../../utils.ts/GetName";
import { getStateOptions } from "../../../utils.ts/StateOptions";
import { getSimplifiedTargetGroupOptions, targetGroupSetToString } from "../../../utils.ts/TargetGroupOptions";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import IconTagOption from "../IconTagOption/IconTagOption";
import DatePicker from "../Inputs/DatePicker/DatePicker";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import StateLabel from "../StateLabel/StateLabel";
import TargetGroupStatusLabel from "../TargetGroupStatusLabel/TargetGroupStatusLabel";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import styles from "./FilterBar.module.scss";
import QueryFilterInput from "./QueryFilterInput";

const stateOptions = getStateOptions();
const targetGroupOptions = getSimplifiedTargetGroupOptions();

type Props = FilterBarPropsWithValue<HappeningFilter> & {
    exclude?: HappeningFilterKeys;
    autoFocus?: boolean;
};

export default function HappeningFilterBar({ value: filters, onChange, exclude, autoFocus }: Props) {
    const defaultFilters = useMemo(() => {
        return { fromDate: undefined, toDate: undefined };
    }, []);
    const tags = useContext(TagsContext).allTags;
    const { value: projectLeaders } = useProjectLeaderOptions();
    const organiserOptions = useOrganiserOptions();
    const leaders: UserNamedType[] = useMemo(
        () => (projectLeaders ? projectLeaders.map((leader) => ({ ...leader, name: getName(leader) })) : []),
        [projectLeaders],
    );

    const onReset = () => {
        onChange(defaultFilters);
    };

    const onChangeGroup = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, groups: filter }),
        [onChange, filters],
    );
    const onChangeStatus = useCallback(
        (filter: HappeningStateOption[]) => onChange({ ...filters, states: filter }),
        [onChange, filters],
    );
    const onChangeLeader = useCallback(
        (filter: UserNamedType[]) => onChange({ ...filters, projectLeaders: filter }),
        [onChange, filters],
    );
    const onChangeInterest = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, happeningTypes: filter }),
        [onChange, filters],
    );
    const onChangeDate = useCallback(
        ([fromDate, toDate]: [Date | null, Date | null]) => {
            toDate?.setHours(23, 59, 59); // toDate has to be at midnight to include activities on that day
            onChange({ ...filters, fromDate: fromDate || undefined, toDate: toDate || undefined });
        },
        [onChange, filters],
    );
    const onChangeTargetGroup = useCallback(
        (filter: SimplifiedTargetGroupOption[]) => onChange({ ...filters, targetGroups: filter }),
        [onChange, filters],
    );

    const shouldShowQuery = useMemo(() => !exclude?.includes("query"), [exclude]);
    const shouldShowGroups = useMemo(() => !exclude?.includes("groups"), [exclude]);
    const shouldShowStates = useMemo(() => !exclude?.includes("states"), [exclude]);
    const shouldShowDate = useMemo(() => !exclude?.includes("fromDate") && !exclude?.includes("toDate"), [exclude]);
    const shouldShowProjectLeaders = useMemo(() => !exclude?.includes("projectLeaders"), [exclude]);
    const shouldShowHappeningTypes = useMemo(() => !exclude?.includes("happeningTypes"), [exclude]);
    const shouldShowTargetGroups = useMemo(() => !exclude?.includes("targetGroups"), [exclude]);

    return (
        <FilterBar onReset={onReset}>
            {shouldShowQuery && <QueryFilterInput filters={filters} onChange={onChange} autoFocus={autoFocus} />}
            {shouldShowDate && (
                <div className={styles.dateFilter}>
                    <DatePicker
                        startDate={filters.fromDate}
                        endDate={filters.toDate}
                        onChange={onChangeDate}
                        selectsRange
                        className={styles.datePicker}
                        placeholderText={strings.date}
                    />
                </div>
            )}
            {shouldShowProjectLeaders && (
                <MultiselectDropdown
                    options={leaders}
                    renderOption={(option) => (
                        <div className={styles.group}>
                            <ProfileIcon profile={option} />
                            <span>{option.name}</span>
                        </div>
                    )}
                    placeholder={strings.projectLeader}
                    name={"leaderFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeLeader}
                    value={filters.projectLeaders}
                    searchable
                    searchPlaceholder={strings.searchByProjectLeaderName}
                />
            )}
            {shouldShowGroups && (
                <MultiselectDropdown
                    options={organiserOptions}
                    optionToKey={(option) => option.id}
                    renderOption={(option) => <IconTagOption tag={option} />}
                    placeholder={strings.happeningGroup}
                    name={"groupFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeGroup}
                    value={filters.groups}
                    searchable
                    searchPlaceholder={strings.searchByOrganisation}
                />
            )}
            {shouldShowStates && (
                <MultiselectDropdown
                    options={stateOptions}
                    optionToString={(option) => statusToString(option.value)}
                    renderOption={(option) => (
                        <div>
                            <StateLabel state={option.value} />
                        </div>
                    )}
                    placeholder={strings.state}
                    name={"statusFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeStatus}
                    value={filters.states}
                />
            )}
            {shouldShowHappeningTypes && (
                <MultiselectDropdown
                    options={tags.happeningTypes}
                    placeholder={strings.interests}
                    name={"interestFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeInterest}
                    value={filters.happeningTypes}
                />
            )}
            {shouldShowTargetGroups && (
                <MultiselectDropdown
                    options={targetGroupOptions}
                    optionToString={(option) => targetGroupSetToString(option.value)}
                    renderOption={(option) => (
                        <div>
                            <TargetGroupStatusLabel target={option.value} />
                        </div>
                    )}
                    placeholder={strings.userSubsets}
                    name={"userSubsetFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeTargetGroup}
                    value={filters.targetGroups}
                />
            )}
        </FilterBar>
    );
}
