import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../../contexts/ProfileContext";
import LoadingSession from "../core/LoadingSession/LoadingSession";
import {
    canAssignApplications,
    canSendGeneralNotifications,
    canViewAppSettings,
    canViewApplications,
    canViewHappenings,
    canViewUsers,
} from "../../authentication/Permissions";

export default function Home() {
    const { profile: user } = useContext(ProfileContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (canViewHappenings(user)) {
            navigate("/activities/all");
        } else if (canViewUsers(user)) {
            navigate("/users/participants");
        } else if (canSendGeneralNotifications(user)) {
            navigate("/notifications/send");
        } else if (canViewAppSettings(user)) {
            navigate("/appsettings/happeningroups");
        } else if (canAssignApplications(user)) {
            navigate("/applications/all");
        } else if (canViewApplications(user)) {
            navigate("/applications/my");
        }
    }, [navigate, user]);

    return <LoadingSession />;
}
