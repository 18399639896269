import cx from "classnames";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CopyIconWhite from "../../../assets/copy.svg";
import DownloadIcon from "../../../assets/downloads.svg";
import ImagePlaceholder from "../../../assets/image_black_24dp.svg";
import LockIcon from "../../../assets/lock_icon.svg";
import HiddenIcon from "../../../assets/hidden_icon.svg";
import PreviewHappeningCardImage from "../../../assets/preview-happening-card.png";
import PreviewHappeningDetailImage from "../../../assets/preview-happening-detail.png";
import PreviewRegistrationImage from "../../../assets/preview-happening-registration-form.png";
import VideoPlaceholder from "../../../assets/videocam_black_24dp.svg";
import {
    canDownloadHappeningParticipants,
    canEditHappening,
    canSendHappeningNotifications,
    canViewProtectedHappeningFields,
} from "../../../authentication/Permissions";
import { LocalHappeningContext } from "../../../contexts/LocalHappeningContext";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { useDownloadHappeningParticipants } from "../../../hooks/HappeningsHooks";
import { SendHappeningNotificationInput, useSendHappeningNotification } from "../../../hooks/NotificationHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import { useMissingImagesQuickfix } from "../../../hooks/useMissingImagesQuickfix";
import strings from "../../../localization/strings";
import {
    HappeningNotificationGroup,
    HappeningRegistrationType,
    HappeningState,
    HappeningType,
    HappeningViewModel,
    NotificationChannel,
    RegistrationStatus,
    TagType,
    TeamViewModel,
    UserSubset,
    Video,
} from "../../../openapi/backend";
import { Size } from "../../../types/Size";
import { hasValues } from "../../../utils.ts/Array";
import { getRecurrenceDescription } from "../../../utils.ts/DateTime";
import { REACT_APP_BUNDLE_IDENTIFIER } from "../../../utils.ts/Env";
import { formatAsDate, formatAsTime } from "../../../utils.ts/Formatting";
import { getStringForHappeningNotificationGroup } from "../../../utils.ts/GetHappeningNotificationGroup";
import { getStringForNotificationType } from "../../../utils.ts/GetNotificationType";
import { showError, showSuccess } from "../../../utils.ts/NotificationSentStatusToast";
import { toastSuccess } from "../../../utils.ts/Toaster";
import { happeningCardLink, happeningDetailLink, registrationLink } from "../../../utils.ts/Urls";
import Accordion from "../../core/Accordion/Accordion";
import AvatarIcon from "../../core/AvatarIcon/AvatarIcon";
import PartnerIcon from "../../core/AvatarIcon/PartnerIcon";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import FieldView from "../../core/FieldView/FieldView";
import Form from "../../core/Form/Form/Form";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import FormMultiSelectField from "../../core/Form/FormMultiSelectField/FormMultiSelectField";
import { FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import HappeningCard from "../../core/HappeningCard/HappeningCard";
import PrivateHappeningCard from "../../core/HappeningCard/PrivateHappeningCard";
import HappeningDate from "../../core/HappeningDate/HappeningDate";
import { NumberSelectField } from "../../core/Inputs/SelectField/SelectField";
import ProfileContent from "../../core/ProfileContent/ProfileContent";
import RegistrationStatisticCard from "../../core/RegistrationStatisticCard/RegistrationStatisticCard";
import StateLabel from "../../core/StateLabel/StateLabel";
import TextButton from "../../core/TextButton/TextButton";
import FlexRow from "../../Layouts/FlexRow/FlexRow";
import HappeningNotificationsHistoryContainer from "../../Notifications/HappeningNotificationsHistoryContainer";
import CreateNewGroupModalContainer from "./CreateNewGroupModal";
import EditButtons from "./EditButtons";
import RegistrationsTable from "./RegistrationsTable";
import ReserveListTable from "./ReserveListTable";
import TeamsTable from "./TeamsTable";
import styles from "./ViewHappening.module.scss";
import ContactDetails from "../../core/ContactDetails/ContactDetails";

const emptyList = [] as TeamViewModel[];

interface ViewHappeningProps {
    happening: HappeningViewModel;
    onDelete: () => void;
    deleteState: RequestState;
    onArchive: () => void;
    onUpdate: () => void;
    onPublish: () => void;
    onCreateCluster: () => void;
    onCancel: () => void;
    onCancelDate: (id: number) => void;
    onSelectParticipant: (id: string) => void;
    onClone: () => void;
    onReject: () => void;
    error?: Response;
}

const TitleSection = ({ happening, ...props }: Omit<ViewHappeningProps, "onCancelDate" | "onSelectParticipant">) => (
    <div className={styles.titleModal}>
        <h1>{happening.title}</h1>

        {happening.isInternal && <img src={LockIcon} alt={strings.internalHappening} />}
        {happening.targetGroup === UserSubset.NotVisibleForParticipation && (
            <img src={HiddenIcon} alt={strings.happeningHiddenForParticipation} />
        )}

        <div className={styles.stateLabel}>
            <StateLabel state={happening.displayState} />
        </div>
        <div className={styles.spacer}></div>
        <EditButtons
            happening={{
                state: happening.state,
                dateTimes: happening.dateTimes,
                projectLeader: happening.projectLeader,
                contact: happening.contact,
                projectMembers: happening.projectMembers,
                projectCoLeaders: happening.projectCoLeaders,
                displayState: happening.displayState,
                cluster: happening.cluster,
                createdBy: happening.createdBy,
            }}
            {...props}
            hasRegisteredParticipants={hasValues(happening.registrations)}
        />
    </div>
);

type LinkCardProps = {
    title: string;
    previewImage: string;
    onGenerateEmbeddedCode?: () => void;
    onCopyLink?: () => void;
};
const LinkCard = ({ title, previewImage, onGenerateEmbeddedCode, onCopyLink }: LinkCardProps) => {
    return (
        <div className={styles.linkCardContainer}>
            <h2>{title}</h2>

            <img className={styles.previewImage} src={previewImage} alt={title} />

            {onGenerateEmbeddedCode && (
                <TextButton
                    className={styles.button}
                    text={strings.copyEmbedCode}
                    onClick={onGenerateEmbeddedCode}
                    icon={CopyIconWhite}
                />
            )}

            {onCopyLink && (
                <TextButton
                    className={cx(styles.button, styles.linkButton)}
                    text={strings.copyLink}
                    onClick={onCopyLink}
                    icon={CopyIconWhite}
                />
            )}
        </div>
    );
};

export default function ViewHappening({
    happening,
    error,
    onCancelDate,
    onSelectParticipant,
    ...props
}: ViewHappeningProps) {
    const { refresh } = useContext(LocalHappeningContext);
    const {
        id,
        title,
        description,
        dateTimes,
        images,
        videos,
        tags,
        projectMembers,
        contact,
        projectLeader,
        projectCoLeaders,
        happeningGroup,
        registrations,
        requiresRegistration,
        currentNumberOfRegistrations,
        recurrence,
        askForSchool,
        schools,
        requiresApprovalFromMarketing,
        registrationStartDate,
        registrationEndDate,
        type,
        displayState,
        targetGroup,
        targetOrganisations,
        extraQuestions,
        cluster,
        happeningRegistrationType,
        teams,
    } = happening;

    const {
        handleSubmit,
        control,
        register,
        getValues,
        watch,
        formState: { errors },
        resetField,
    } = useForm<SendHappeningNotificationInput>();

    const { profile } = useContext(ProfileContext);

    const hotfixSBG2344Images = useMissingImagesQuickfix(images);

    const canViewProtectedFields = canViewProtectedHappeningFields(happening, profile);
    const canDownloadParticipantsList = canDownloadHappeningParticipants(happening, profile);
    const [createGroupOpen, setCreateGroupOpen] = useState(false);
    const openCreateGroup = useCallback(() => setCreateGroupOpen(true), []);
    const closeCreateGroup = useCallback(() => setCreateGroupOpen(false), []);
    const onCreateGroupSuccess = useCallback(() => {
        refresh();
        closeCreateGroup();
    }, [closeCreateGroup, refresh]);

    const [{ state }, sendNotification] = useSendHappeningNotification();
    const partners = tags.filter((x) => x.type === TagType.Partner || x.type === TagType.HappeningGroup);
    const internalTags = tags.filter((x) => x.type === TagType.Internal);
    const hasLocations =
        tags.filter(
            (tag) =>
                tag.type === TagType.Municipality ||
                tag.type === TagType.District ||
                tag.type === TagType.Neighbourhood,
        ).length > 0;

    const isPrivateHappening = useMemo(() => type === HappeningType.PrivateHappening, [type]);

    const hasExtraOptions =
        hasLocations ||
        schools.length > 0 ||
        askForSchool ||
        requiresApprovalFromMarketing ||
        hasValues(extraQuestions) ||
        registrationStartDate ||
        registrationEndDate;

    const happeningFilterOptionsChannel = watch("happeningFilterOptionsChannel");

    const [notificationsHistoryKey, setNotificationsHistoryKey] = useState(0);

    const canceledRegistrations = useMemo(() => {
        return registrations.filter((registration) => registration.status === RegistrationStatus.Canceled);
    }, [registrations]);

    const reserveSpotRegistrations = useMemo(() => {
        return happening.reserveList ?? [];
    }, [happening]);

    const [selectedStatisticDateTime, setSelectedStatisticDateTime] = useState<number>(
        dateTimes.find(() => true)?.id ?? 0,
    );

    const isGroupRegistration = useMemo(() => {
        return happeningRegistrationType === HappeningRegistrationType.GroupRegistration;
    }, [happeningRegistrationType]);

    const isIndividualRegistration = useMemo(() => {
        return happeningRegistrationType === HappeningRegistrationType.SoloRegistration;
    }, [happeningRegistrationType]);

    const copyRegistrationEmbedCode = useCallback(async () => {
        var code = `<iframe src="${registrationLink(
            happening,
        )}" frameBorder="0" width="100%" height="1080px" allow="clipboard-write"></iframe>`;
        await navigator.clipboard.writeText(code);
        toastSuccess(strings.confirmCopyEmbedCode);
    }, [happening]);

    const copyRegistrationLink = useCallback(async () => {
        var link = registrationLink(happening);
        await navigator.clipboard.writeText(link);
        toastSuccess(strings.confirmCopyLink);
    }, [happening]);

    const copyDetailEmbedCode = useCallback(async () => {
        var code = `<iframe src="${happeningDetailLink(
            happening,
        )}?embed=1" frameBorder="0" width="100%" height="1080px" allow="clipboard-write"></iframe>`;
        await navigator.clipboard.writeText(code);
        toastSuccess(strings.confirmCopyEmbedCode);
    }, [happening]);

    const copyDetailLink = useCallback(async () => {
        var link = `${happeningDetailLink(happening)}?embed=1`;
        await navigator.clipboard.writeText(link);
        toastSuccess(strings.confirmCopyLink);
    }, [happening]);

    const copyMiniDetailEmbedCode = useCallback(async () => {
        var code = `<iframe src="${happeningCardLink(
            happening,
        )}" frameBorder="0" width="268px" height="306px" allow="clipboard-write"></iframe>`;
        await navigator.clipboard.writeText(code);
        toastSuccess(strings.confirmCopyEmbedCode);
    }, [happening]);
    const [, downloadHappeningParticipants] = useDownloadHappeningParticipants();

    useEffect(() => {
        if (state === RequestState.DONE) {
            showSuccess(getValues("happeningFilterOptionsChannel"));
            resetField("title");
            resetField("body");
            setNotificationsHistoryKey((key) => key + 1);
        }
    }, [state, getValues, resetField]);

    useEffect(() => {
        if (state === RequestState.ERROR) {
            showError(getValues("happeningFilterOptionsChannel"));
        }
    }, [state, getValues, resetField]);

    const originalPublicationDate = useMemo(() => {
        if (displayState !== HappeningState.PlannedForPublication) {
            return undefined;
        }

        return happening.publicationDate;
    }, [displayState, happening.publicationDate]);

    return (
        <>
            <Accordion
                title={<TitleSection happening={happening} {...props} />}
                className={cx(cluster && styles.dockTabs)}
            >
                {error && <ErrorMessage error={error} isToast />}

                {originalPublicationDate && moment().isBefore(moment(originalPublicationDate)) && (
                    <div className={styles.generalNotification}>
                        {
                            strings.formatString(
                                strings.publicationDateNotification,
                                formatAsDate(originalPublicationDate),
                                formatAsTime(originalPublicationDate),
                            ) as string
                        }
                    </div>
                )}

                {happening.state === HappeningState.PartnerSuggestion && (
                    <ContactDetails contact={happening.createdBy ?? happening.contact} />
                )}

                {isPrivateHappening ? (
                    <PrivateHappeningCard privateHappening={happening} />
                ) : (
                    <HappeningCard happening={happening} />
                )}

                <div className={styles.descriptionContainer}>
                    <div className={styles.description}>
                        <FieldView label={strings.description} allowNewLines={true}>
                            {description}
                        </FieldView>

                        {happening.link && (
                            <FieldView className={styles.url} label={strings.linkHint}>
                                <TextButton
                                    buttonType={"tertiary"}
                                    text={happening.linkLabel || happening.link}
                                    className={styles.urlText}
                                    onClick={() => window.open(happening.link)}
                                />
                            </FieldView>
                        )}

                        {internalTags.length > 0 && (
                            <FieldView
                                className={`${styles.internalTags} ${styles.field}`}
                                label={strings.internalTags}
                            >
                                <ul>
                                    {internalTags.map((tag) => (
                                        <li key={tag.id}>{tag.detailedName}</li>
                                    ))}
                                </ul>
                            </FieldView>
                        )}
                    </div>

                    <div className={styles.image}>
                        {happeningGroup?.logo && (
                            <AvatarIcon
                                image={happeningGroup.logo.href}
                                size={Size.LARGE}
                                color={happeningGroup.color}
                                name={happeningGroup.detailedName}
                            />
                        )}
                    </div>
                </div>
            </Accordion>

            <Accordion title={strings.media}>
                <div className={styles.imagesContainer}>
                    {hotfixSBG2344Images.map((image, index) => {
                        return (
                            <img
                                className={styles.image}
                                key={index}
                                src={image.href ?? ImagePlaceholder}
                                alt={image.altText ?? ""}
                                onClick={() => (image.href ? window.open(image.href) : {})}
                            />
                        );
                    })}
                    {videos.map((video: Video, index: number) => {
                        return (
                            <img
                                className={styles.video}
                                key={hotfixSBG2344Images.length + index}
                                src={VideoPlaceholder}
                                alt={video.altText ?? ""}
                                onClick={() => (video.href ? window.open(video.href) : {})}
                            />
                        );
                    })}
                </div>
            </Accordion>

            {targetGroup === UserSubset.SelectedOrganisations ? (
                <Accordion title={strings.targetAudience}>
                    <ul className={styles.partnerList}>
                        {targetOrganisations.map((organisation, index) => {
                            return (
                                <li className={styles.partner} key={`${organisation.name}${index}`}>
                                    {organisation.detailedName}
                                </li>
                            );
                        })}
                    </ul>
                </Accordion>
            ) : null}

            {hasExtraOptions && (
                <Accordion title={strings.extraOptionsRegistration}>
                    <ul className={styles.extraOptionsContainer}>
                        {requiresApprovalFromMarketing && (
                            <li>
                                <div>{strings.requiresApprovalFromMarketing}</div>
                            </li>
                        )}

                        {askForSchool && (
                            <li>
                                <div>{strings.askForSchool}</div>
                            </li>
                        )}

                        {schools.length > 0 && (
                            <li>
                                <div className={styles.table}>
                                    <div className={styles.column}>
                                        <div>{strings.restrictedSchools}</div>
                                        <ul>
                                            {schools.map((school, index) => (
                                                <li key={`${school}-${index}`}>{school}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )}

                        {hasLocations && (
                            <li>
                                <div>
                                    <div>{strings.restrictAreas}</div>
                                    <div className={styles.table}>
                                        <div className={styles.column}>
                                            <div className={styles.areaType}>{strings.municipalities}</div>
                                            {tags
                                                .filter((tag) => tag.type === TagType.Municipality)
                                                .map((tag) => (
                                                    <div className={styles.areaName} key={tag.id}>
                                                        {tag.detailedName}
                                                    </div>
                                                ))}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.areaType}>{strings.districts}</div>
                                            {tags
                                                .filter((tag) => tag.type === TagType.District)
                                                .map((tag) => (
                                                    <div className={styles.areaName} key={tag.id}>
                                                        {tag.detailedName}
                                                    </div>
                                                ))}
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.areaType}>{strings.neighbourhoods}</div>
                                            {tags
                                                .filter((tag) => tag.type === TagType.Neighbourhood)
                                                .map((tag) => (
                                                    <div className={styles.areaName} key={tag.id}>
                                                        {tag.detailedName}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}

                        {registrationStartDate && (
                            <li>
                                <div>{`${strings.formatString(
                                    strings.registerFrom,
                                    moment(registrationStartDate).format("DD-MM-YYYY"),
                                    moment(registrationStartDate).format("HH:mm"),
                                )}.`}</div>
                            </li>
                        )}

                        {registrationEndDate && (
                            <li>
                                <div>{`${strings.formatString(
                                    strings.registerUntil,
                                    moment(registrationEndDate).format("DD-MM-YYYY"),
                                    moment(registrationEndDate).format("HH:mm"),
                                )}.`}</div>
                            </li>
                        )}

                        {extraQuestions.map((extraQuestion) => {
                            return (
                                <li key={extraQuestion.id}>
                                    <div>
                                        <div>{`${strings.extraOpenQuestion} ${
                                            extraQuestion.isRequired ? `(${strings.mandatory})` : ""
                                        }:`}</div>
                                        <div className={styles.question}>{extraQuestion.title}</div>
                                        {hasValues(extraQuestion.options) && (
                                            <div>
                                                <div>{strings.viewConfigureMultipleChoice}</div>
                                                <ul>
                                                    {extraQuestion.options.map((q, i) => (
                                                        <li key={`${q}-${i}`}>{q}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </Accordion>
            )}

            <Accordion title={strings.linkHeader}>
                <div className={styles.row}>
                    <div>
                        <FieldView className={styles.field} label={strings.projectLeader}>
                            {projectLeader ? <ProfileContent profile={projectLeader} /> : "-"}
                        </FieldView>
                        <FieldView className={styles.field} label={strings.contact}>
                            {contact ? <ProfileContent profile={contact} /> : "-"}
                        </FieldView>
                    </div>
                    {hasValues(projectCoLeaders) && (
                        <FieldView label={strings.linkProjectCoLeaders}>
                            {projectCoLeaders.map((member) => (
                                <ProfileContent key={member.id} profile={member} />
                            ))}
                        </FieldView>
                    )}
                    <FieldView label={strings.editRightsGivenTo}>
                        {hasValues(projectMembers)
                            ? projectMembers.map((member, index) => (
                                  <ProfileContent key={`${strings.accounts}-${index}`} profile={member} />
                              ))
                            : "-"}
                    </FieldView>
                </div>
            </Accordion>

            {hasValues(partners) && (
                <Accordion title={strings.collaboration}>
                    <ul className={styles.partnerList}>
                        {partners.map((partner, index) => {
                            return (
                                <div className={styles.partner} key={`${partner.name}${index}`}>
                                    <PartnerIcon partner={partner} showResponsibilities showVisibilityInCommunication />
                                </div>
                            );
                        })}
                    </ul>
                </Accordion>
            )}

            {!isPrivateHappening && (
                <Accordion title={strings.dateAndTime}>
                    <HappeningDate
                        dateTimes={dateTimes}
                        happeningState={displayState}
                        happeningGroup={happeningGroup}
                        title={title}
                        onCancelDate={onCancelDate}
                        preventCancel={dateTimes.length === 1 || !canEditHappening(happening, profile)}
                    />
                    {!!recurrence && (
                        <div className={styles.subText}>{getRecurrenceDescription(recurrence, displayState)}</div>
                    )}
                </Accordion>
            )}

            {canViewProtectedFields && isGroupRegistration && (
                <Accordion
                    title={strings.formatString(
                        strings.teamsHeaderCounter,
                        teams ? teams.length : 0,
                        happening.maxNumberOfRegistrations,
                    )}
                    headerContent={
                        <FlexRow>
                            <TextButton
                                text={strings.createNewTeam}
                                onClick={openCreateGroup}
                                disabled={
                                    happening.maxNumberOfRegistrations > 0 &&
                                    hasValues(teams) &&
                                    teams.length >= happening.maxNumberOfRegistrations
                                }
                            />
                            <TextButton
                                text={strings.downloadList}
                                onClick={() => {
                                    downloadHappeningParticipants(id);
                                }}
                                buttonType="alternate"
                                icon={DownloadIcon}
                            />
                            {createGroupOpen && (
                                <CreateNewGroupModalContainer
                                    happeningId={id}
                                    onDismiss={closeCreateGroup}
                                    onSuccess={onCreateGroupSuccess}
                                />
                            )}
                        </FlexRow>
                    }
                >
                    <TeamsTable teams={teams ? teams : emptyList} happening={happening} searchable />
                </Accordion>
            )}

            {canViewProtectedFields && (hasValues(registrations) || isIndividualRegistration) && (
                <Accordion
                    title={`${isGroupRegistration ? strings.contactPersons : strings.signups} (${
                        registrations.length - canceledRegistrations.length
                    })`}
                    headerContent={
                        isIndividualRegistration &&
                        canDownloadParticipantsList && (
                            <FlexRow>
                                <TextButton
                                    text={strings.downloadList}
                                    onClick={() => {
                                        downloadHappeningParticipants(id);
                                    }}
                                    buttonType="alternate"
                                    icon={DownloadIcon}
                                />
                            </FlexRow>
                        )
                    }
                >
                    <RegistrationsTable
                        groupBySessions={isIndividualRegistration}
                        happening={happening}
                        onSelectParticipant={onSelectParticipant}
                        happeningRegistrations={registrations}
                        searchable={true}
                    />
                </Accordion>
            )}

            {canViewProtectedFields && hasValues(reserveSpotRegistrations) && (
                <Accordion title={`${strings.reserveList} (${reserveSpotRegistrations.length})`}>
                    <ReserveListTable
                        happening={happening}
                        reserveList={reserveSpotRegistrations}
                        onSelectParticipant={onSelectParticipant}
                    />
                </Accordion>
            )}

            {canViewProtectedFields && hasValues(canceledRegistrations) && (
                <Accordion title={`${strings.notSignedUps} (${canceledRegistrations.length})`}>
                    <RegistrationsTable
                        happening={happening}
                        onSelectParticipant={onSelectParticipant}
                        happeningRegistrations={canceledRegistrations}
                        searchable={true}
                    />
                </Accordion>
            )}

            {canViewProtectedFields && (
                <Accordion title={`Statistieken`}>
                    {dateTimes.length > 1 && (
                        <NumberSelectField
                            value={selectedStatisticDateTime}
                            onChange={(value: number) => setSelectedStatisticDateTime(value)}
                            name={`statistics-date-picker`}
                            options={dateTimes.map((time) => {
                                return {
                                    label: time.sessionStart.toLocaleDateString(strings.getLanguage(), {
                                        dateStyle: "full",
                                    }),
                                    value: time.id,
                                };
                            })}
                        />
                    )}
                    <div className={styles.statisticsContainer}>
                        <RegistrationStatisticCard
                            label={requiresRegistration ? strings.statRegistered : strings.statRegisteredInstuif}
                            value={currentNumberOfRegistrations}
                        />
                        {requiresRegistration && (
                            <RegistrationStatisticCard
                                label={strings.statUnregistered}
                                value={registrations.filter((x) => x.status === RegistrationStatus.Canceled).length}
                            />
                        )}
                        {dateTimes
                            .filter((x) => x.id === selectedStatisticDateTime)
                            .map((dateTime) => (
                                <React.Fragment key={`fragment-${dateTime.id}`}>
                                    <RegistrationStatisticCard
                                        key={`present-${dateTime.id}`}
                                        label={strings.statPresent}
                                        value={happening.presenceStatistics[dateTime.id].present ?? 0}
                                    />
                                    {requiresRegistration && (
                                        <RegistrationStatisticCard
                                            key={`absent-${dateTime.id}`}
                                            label={strings.statAbsent}
                                            value={happening.presenceStatistics[dateTime.id].absent ?? 0}
                                        />
                                    )}
                                    {requiresRegistration && (
                                        <RegistrationStatisticCard
                                            key={`unknown-${dateTime.id}`}
                                            label={strings.statUnknown}
                                            value={happening.presenceStatistics[dateTime.id].unknown ?? 0}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                    </div>
                </Accordion>
            )}

            {!isPrivateHappening && canSendHappeningNotifications(happening, profile) && (
                <Accordion title={strings.communication}>
                    <Form
                        submitText={strings.sendNotification}
                        onSubmit={(formInfo) =>
                            sendNotification({
                                ...formInfo,
                                happeningFilterOptionsHappeningId: id,
                                link: `${REACT_APP_BUNDLE_IDENTIFIER}://happening/${id}`,
                            })
                        }
                        handleSubmit={handleSubmit}
                        waitingForSubmit={state === RequestState.LOADING}
                    >
                        <div className={styles.halfSection}>
                            <FormStringSelect
                                name={"happeningFilterOptionsChannel"}
                                defaultValue={undefined}
                                placeholder={" "}
                                options={(
                                    Object.values(NotificationChannel) as Array<keyof typeof NotificationChannel>
                                ).map((t) => ({
                                    label: getStringForNotificationType(t),
                                    value: t,
                                }))}
                                label={strings.typeOfNotification}
                                control={control}
                                errors={errors.happeningFilterOptionsChannel}
                                required
                            />
                        </div>

                        <div className={styles.halfSection}>
                            <FormMultiSelectField
                                name={"happeningFilterOptionsGroups"}
                                label={strings.recipient}
                                placeholder={" "}
                                options={(
                                    Object.values(HappeningNotificationGroup) as Array<
                                        keyof typeof HappeningNotificationGroup
                                    >
                                ).map((t) => ({
                                    label: getStringForHappeningNotificationGroup(t),
                                    value: t,
                                }))}
                                control={control}
                                errors={errors.happeningFilterOptionsGroups}
                                required
                            />
                        </div>

                        <FormFieldMultiline
                            rows={1}
                            name={"title"}
                            register={register}
                            label={strings.subject}
                            placeholder={""}
                            required
                            errors={errors.title}
                            maxLength={78}
                            canResize={false}
                        />

                        <FormFieldMultiline
                            rows={4}
                            name="body"
                            register={register}
                            label={strings.message}
                            required
                            errors={errors.body}
                            maxLength={
                                happeningFilterOptionsChannel === NotificationChannel.PushNotification ? 178 : undefined
                            }
                        />
                    </Form>

                    <HappeningNotificationsHistoryContainer id={id} key={notificationsHistoryKey} />
                </Accordion>
            )}

            {canViewProtectedFields && (
                <Accordion title={strings.happeningLinks}>
                    <FlexRow className={styles.linksContainer}>
                        {requiresRegistration && (
                            <LinkCard
                                title={strings.happeningLinkRegistrationForm}
                                previewImage={PreviewRegistrationImage}
                                onGenerateEmbeddedCode={copyRegistrationEmbedCode}
                                onCopyLink={copyRegistrationLink}
                            />
                        )}
                        <LinkCard
                            title={strings.happeningLinkView}
                            previewImage={PreviewHappeningDetailImage}
                            onGenerateEmbeddedCode={copyDetailEmbedCode}
                            onCopyLink={copyDetailLink}
                        />
                        <LinkCard
                            title={strings.happeningLinkCard}
                            previewImage={PreviewHappeningCardImage}
                            onGenerateEmbeddedCode={copyMiniDetailEmbedCode}
                        />
                    </FlexRow>
                </Accordion>
            )}
        </>
    );
}
