import cx from "classnames";
import React, { useMemo } from "react";
import { SimplifiedTargetGroupSet } from "../../../types/SimplifiedTargetGroupSet";
import { targetGroupSetToString } from "../../../utils.ts/TargetGroupOptions";
import styles from "./TargetGroupStatusLabel.module.scss";

interface Props {
    target?: SimplifiedTargetGroupSet;
}

function getStyle(status: SimplifiedTargetGroupSet) {
    switch (status) {
        case SimplifiedTargetGroupSet.Participant:
            return styles.participant;
        case SimplifiedTargetGroupSet.Internal:
            return styles.internal;
        case SimplifiedTargetGroupSet.NotVisible:
            return styles.notVisible;
    }
}

export default function TargetGroupStatusLabel({ target }: Props) {
    const targetStyle = useMemo(() => target && getStyle(target), [target]);

    return (
        <label id={target} className={cx(styles.container, targetStyle)} htmlFor={target}>
            {target && targetGroupSetToString(target)}
        </label>
    );
}
