import { canSendGeneralNotifications } from "../authentication/Permissions";
import Notifications from "../components/Notifications/Notifications";
import NotificationsHistoryContainer from "../components/Notifications/NotificationsHistoryContainer";
import strings from "../localization/strings";
import { NavigationTab } from "../types/NavigationTab";
import useTabRoute from "./UseTabRoute";

const notificationsTabs: NavigationTab[] = [
    {
        pathname: "send",
        label: strings.send,
        element: <Notifications />,
    },
    {
        pathname: "sent",
        label: strings.sentPage,
        element: <NotificationsHistoryContainer />,
    },
];

const useNotificationsRoute = () => useTabRoute("notifications", notificationsTabs, [canSendGeneralNotifications]);

export default useNotificationsRoute;
