/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    Gender,
    GenderFromJSON,
    GenderToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProfileOutput,
    ProfileOutputFromJSON,
    ProfileOutputToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
} from "../models";

export interface CreateSubprofileRequest {
    version: string;
    givenName: string;
    familyName: string;
    email?: string;
    tags?: Array<string>;
    preferredFilters?: Array<string>;
    dateOfBirth?: Date;
    phoneNumber?: string;
    profession?: string;
    postalCode?: string;
    gender?: Gender;
    school?: string;
    medicalNotes?: string;
}

export interface DeleteProfileRequest {
    id: string;
    version: string;
}

export interface GetParticipantsRequest {
    version: string;
    roles?: Array<Role>;
    query?: string;
    emailQuery?: string;
    partners?: Array<string>;
    happeningGroups?: Array<string>;
}

export interface GetProfileRequest {
    id: string;
    version: string;
}

export interface UpdateMyProfileRequest {
    version: string;
    givenName: string;
    familyName: string;
    email?: string;
    tags?: Array<string>;
    preferredFilters?: Array<string>;
    dateOfBirth?: Date;
    phoneNumber?: string;
    profession?: string;
    postalCode?: string;
    gender?: Gender;
    school?: string;
    medicalNotes?: string;
}

export interface UpdateMyProfilePictureRequest {
    version: string;
    altText: string;
    file: Blob;
}

export interface UpdateProfileRequest {
    id: string;
    version: string;
    givenName: string;
    familyName: string;
    email?: string;
    tags?: Array<string>;
    preferredFilters?: Array<string>;
    dateOfBirth?: Date;
    phoneNumber?: string;
    profession?: string;
    postalCode?: string;
    gender?: Gender;
    school?: string;
    medicalNotes?: string;
}

export interface UpdateProfilePictureRequest {
    id: string;
    version: string;
    altText: string;
    file: Blob;
}

/**
 * no description
 */
export class ProfilesApi extends runtime.BaseAPI {
    /**
     */
    async createSubprofileRaw(requestParameters: CreateSubprofileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createSubprofile.",
            );
        }
        if (requestParameters.givenName === null || requestParameters.givenName === undefined) {
            throw new runtime.RequiredError(
                "givenName",
                "Required parameter requestParameters.givenName was null or undefined when calling createSubprofile.",
            );
        }
        if (requestParameters.familyName === null || requestParameters.familyName === undefined) {
            throw new runtime.RequiredError(
                "familyName",
                "Required parameter requestParameters.familyName was null or undefined when calling createSubprofile.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.email !== undefined) {
            formParams.append("Email", requestParameters.email as any);
            hasParams = true;
        }
        if (requestParameters.givenName !== undefined) {
            formParams.append("GivenName", requestParameters.givenName as any);
            hasParams = true;
        }
        if (requestParameters.familyName !== undefined) {
            formParams.append("FamilyName", requestParameters.familyName as any);
            hasParams = true;
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.preferredFilters) {
            requestParameters.preferredFilters.forEach((element, index) => {
                appendToParams(formParams, `PreferredFilters[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append("DateOfBirth", (requestParameters.dateOfBirth as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.phoneNumber !== undefined) {
            formParams.append("PhoneNumber", requestParameters.phoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.profession !== undefined) {
            formParams.append("Profession", requestParameters.profession as any);
            hasParams = true;
        }
        if (requestParameters.postalCode !== undefined) {
            formParams.append("PostalCode", requestParameters.postalCode as any);
            hasParams = true;
        }
        if (requestParameters.gender !== undefined) {
            formParams.append("Gender", requestParameters.gender as any);
            hasParams = true;
        }
        if (requestParameters.school !== undefined) {
            formParams.append("School", requestParameters.school as any);
            hasParams = true;
        }
        if (requestParameters.medicalNotes !== undefined) {
            formParams.append("MedicalNotes", requestParameters.medicalNotes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Profiles`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async createSubprofile(requestParameters: CreateSubprofileRequest): Promise<void> {
        await this.createSubprofileRaw(requestParameters);
    }
    /**
     */
    async deleteProfileRaw(requestParameters: DeleteProfileRequest): Promise<runtime.ApiResponse<ProfileOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteProfile.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteProfile.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileOutputFromJSON(jsonValue));
    }
    /**
     */
    async deleteProfile(requestParameters: DeleteProfileRequest): Promise<ProfileOutput> {
        const response = await this.deleteProfileRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getParticipantsRaw(
        requestParameters: GetParticipantsRequest,
    ): Promise<runtime.ApiResponse<Array<ProfileOutput>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getParticipants.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.roles && requestParameters.roles.length > 0) {
            queryParameters["Roles"] = requestParameters.roles;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.emailQuery !== undefined) {
            queryParameters["EmailQuery"] = requestParameters.emailQuery;
        }
        if (requestParameters.partners && requestParameters.partners.length > 0) {
            queryParameters["Partners"] = requestParameters.partners;
        }
        if (requestParameters.happeningGroups && requestParameters.happeningGroups.length > 0) {
            queryParameters["HappeningGroups"] = requestParameters.happeningGroups;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Profiles/participants`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileOutputFromJSON));
    }
    /**
     */
    async getParticipants(requestParameters: GetParticipantsRequest): Promise<Array<ProfileOutput>> {
        const response = await this.getParticipantsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getProfileRaw(requestParameters: GetProfileRequest): Promise<runtime.ApiResponse<ProfileOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getProfile.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getProfile.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileOutputFromJSON(jsonValue));
    }
    /**
     */
    async getProfile(requestParameters: GetProfileRequest): Promise<ProfileOutput> {
        const response = await this.getProfileRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateMyProfileRaw(requestParameters: UpdateMyProfileRequest): Promise<runtime.ApiResponse<ProfileOutput>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateMyProfile.",
            );
        }
        if (requestParameters.givenName === null || requestParameters.givenName === undefined) {
            throw new runtime.RequiredError(
                "givenName",
                "Required parameter requestParameters.givenName was null or undefined when calling updateMyProfile.",
            );
        }
        if (requestParameters.familyName === null || requestParameters.familyName === undefined) {
            throw new runtime.RequiredError(
                "familyName",
                "Required parameter requestParameters.familyName was null or undefined when calling updateMyProfile.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.email !== undefined) {
            formParams.append("Email", requestParameters.email as any);
            hasParams = true;
        }
        if (requestParameters.givenName !== undefined) {
            formParams.append("GivenName", requestParameters.givenName as any);
            hasParams = true;
        }
        if (requestParameters.familyName !== undefined) {
            formParams.append("FamilyName", requestParameters.familyName as any);
            hasParams = true;
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.preferredFilters) {
            requestParameters.preferredFilters.forEach((element, index) => {
                appendToParams(formParams, `PreferredFilters[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append("DateOfBirth", (requestParameters.dateOfBirth as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.phoneNumber !== undefined) {
            formParams.append("PhoneNumber", requestParameters.phoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.profession !== undefined) {
            formParams.append("Profession", requestParameters.profession as any);
            hasParams = true;
        }
        if (requestParameters.postalCode !== undefined) {
            formParams.append("PostalCode", requestParameters.postalCode as any);
            hasParams = true;
        }
        if (requestParameters.gender !== undefined) {
            formParams.append("Gender", requestParameters.gender as any);
            hasParams = true;
        }
        if (requestParameters.school !== undefined) {
            formParams.append("School", requestParameters.school as any);
            hasParams = true;
        }
        if (requestParameters.medicalNotes !== undefined) {
            formParams.append("MedicalNotes", requestParameters.medicalNotes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Profiles/me`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileOutputFromJSON(jsonValue));
    }
    /**
     */
    async updateMyProfile(requestParameters: UpdateMyProfileRequest): Promise<ProfileOutput> {
        const response = await this.updateMyProfileRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateMyProfilePictureRaw(
        requestParameters: UpdateMyProfilePictureRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateMyProfilePicture.",
            );
        }
        if (requestParameters.altText === null || requestParameters.altText === undefined) {
            throw new runtime.RequiredError(
                "altText",
                "Required parameter requestParameters.altText was null or undefined when calling updateMyProfilePicture.",
            );
        }
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError(
                "file",
                "Required parameter requestParameters.file was null or undefined when calling updateMyProfilePicture.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.altText !== undefined) {
            formParams.append("AltText", requestParameters.altText as any);
            hasParams = true;
        }
        if (requestParameters.file !== undefined) {
            formParams.append("File", requestParameters.file as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Profiles/me/profile-picture`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateMyProfilePicture(requestParameters: UpdateMyProfilePictureRequest): Promise<void> {
        await this.updateMyProfilePictureRaw(requestParameters);
    }
    /**
     */
    async updateProfileRaw(requestParameters: UpdateProfileRequest): Promise<runtime.ApiResponse<ProfileOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateProfile.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateProfile.",
            );
        }
        if (requestParameters.givenName === null || requestParameters.givenName === undefined) {
            throw new runtime.RequiredError(
                "givenName",
                "Required parameter requestParameters.givenName was null or undefined when calling updateProfile.",
            );
        }
        if (requestParameters.familyName === null || requestParameters.familyName === undefined) {
            throw new runtime.RequiredError(
                "familyName",
                "Required parameter requestParameters.familyName was null or undefined when calling updateProfile.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.email !== undefined) {
            formParams.append("Email", requestParameters.email as any);
            hasParams = true;
        }
        if (requestParameters.givenName !== undefined) {
            formParams.append("GivenName", requestParameters.givenName as any);
            hasParams = true;
        }
        if (requestParameters.familyName !== undefined) {
            formParams.append("FamilyName", requestParameters.familyName as any);
            hasParams = true;
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.preferredFilters) {
            requestParameters.preferredFilters.forEach((element, index) => {
                appendToParams(formParams, `PreferredFilters[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append("DateOfBirth", (requestParameters.dateOfBirth as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.phoneNumber !== undefined) {
            formParams.append("PhoneNumber", requestParameters.phoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.profession !== undefined) {
            formParams.append("Profession", requestParameters.profession as any);
            hasParams = true;
        }
        if (requestParameters.postalCode !== undefined) {
            formParams.append("PostalCode", requestParameters.postalCode as any);
            hasParams = true;
        }
        if (requestParameters.gender !== undefined) {
            formParams.append("Gender", requestParameters.gender as any);
            hasParams = true;
        }
        if (requestParameters.school !== undefined) {
            formParams.append("School", requestParameters.school as any);
            hasParams = true;
        }
        if (requestParameters.medicalNotes !== undefined) {
            formParams.append("MedicalNotes", requestParameters.medicalNotes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileOutputFromJSON(jsonValue));
    }
    /**
     */
    async updateProfile(requestParameters: UpdateProfileRequest): Promise<ProfileOutput> {
        const response = await this.updateProfileRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateProfilePictureRaw(requestParameters: UpdateProfilePictureRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateProfilePicture.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateProfilePicture.",
            );
        }
        if (requestParameters.altText === null || requestParameters.altText === undefined) {
            throw new runtime.RequiredError(
                "altText",
                "Required parameter requestParameters.altText was null or undefined when calling updateProfilePicture.",
            );
        }
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError(
                "file",
                "Required parameter requestParameters.file was null or undefined when calling updateProfilePicture.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.altText !== undefined) {
            formParams.append("AltText", requestParameters.altText as any);
            hasParams = true;
        }
        if (requestParameters.file !== undefined) {
            formParams.append("File", requestParameters.file as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Profiles/{id}/profile-picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateProfilePicture(requestParameters: UpdateProfilePictureRequest): Promise<void> {
        await this.updateProfilePictureRaw(requestParameters);
    }
}
