import strings from "../../../localization/strings";
import {
    HappeningRegistrationType,
    HappeningViewModel,
    LocationType,
    LocationViewModel,
    TagType,
} from "../../../openapi/backend";
import HappeningField from "../HappeningField/HappeningField";
import styles from "./HappeningCard.module.scss";
import Map from "../Map/Map";
import { DEFAULT_COLOR } from "../../../constants/Defaults";
import { formatAsPrice } from "../../../utils.ts/Formatting";
import { getLocationTypeString } from "../../../utils.ts/GetLocationTypeString";
import { useCallback, useMemo } from "react";
import { getStringForHappeningTargetGroup } from "../../../utils.ts/GetHappeningTargetGroup";
import { happeningRegistrationTypeToString } from "../../../utils.ts/GetHappeningRegistrationType";
import { formatAsPriceGroup, formatAsPriceIndividual } from "../../../utils.ts/Formatting";

interface HappeningCardProps {
    happening: HappeningViewModel;
}

const Location = ({ location, type }: { location?: LocationViewModel; type: LocationType }) =>
    type === LocationType.OnLocation && location ? (
        <div className={`${styles.container} ${styles.locationContainer}`}>
            <div>
                {location.name} <br /> {location.address}
            </div>

            <Map location={location} className={styles.map} />
        </div>
    ) : (
        <div className={styles.container}>{getLocationTypeString(type)}</div>
    );

export default function HappeningCard({ happening }: HappeningCardProps) {
    const {
        maxNumberOfRegistrations,
        happeningGroup,
        location,
        price,
        priceForIndividual,
        isProRata,
        tags,
        minAge,
        maxAge,
        isAccessible,
        locationType,
        isInternal,
        targetGroup,
        happeningRegistrationType,
        minPeoplePerGroup,
        maxPeoplePerGroup,
        isGroupHappening,
        canOnlyRegisterAsGroup,
    } = happening;

    const prices = useMemo(() => {
        var priceTexts = [];
        if (isGroupHappening && !canOnlyRegisterAsGroup) {
            priceTexts.push(formatAsPriceGroup(price));
            priceTexts.push(formatAsPriceIndividual(priceForIndividual));
        } else {
            priceTexts.push(formatAsPrice(price));
        }
        if (isProRata) {
            priceTexts.push(strings.proRata);
        }
        return priceTexts;
    }, [price, isGroupHappening, canOnlyRegisterAsGroup, priceForIndividual, isProRata]);

    const targetAudience = useMemo(() => {
        var targetAudienceTexts = [];
        if (isInternal) {
            targetAudienceTexts.push(getStringForHappeningTargetGroup(targetGroup));
        } else {
            targetAudienceTexts.push(strings.formatString(strings.ageRangeDiscription, minAge, maxAge) as string);
        }
        if (isAccessible) {
            targetAudienceTexts.push(strings.isAccessible);
        }
        return targetAudienceTexts;
    }, [isInternal, minAge, maxAge, isAccessible, targetGroup]);

    const RegistrationsField = useCallback(() => {
        const val =
            maxNumberOfRegistrations > 0
                ? maxNumberOfRegistrations
                : strings.unlimited.charAt(0).toUpperCase() + strings.unlimited.slice(1);
        switch (happeningRegistrationType) {
            case HappeningRegistrationType.GroupRegistration:
                return <HappeningField title={strings.maxRegistrationGroups} value={val} />;
            case HappeningRegistrationType.SoloRegistration:
                return <HappeningField title={strings.maxRegistrations} value={val} />;
            default:
                return null;
        }
    }, [maxNumberOfRegistrations, happeningRegistrationType]);

    return (
        <div className={styles.happeningCard} style={{ backgroundColor: happeningGroup?.color ?? DEFAULT_COLOR }}>
            <div className={styles.container}>
                <HappeningField title={strings.targetAudience} list value={targetAudience} />
                <RegistrationsField />
                {happeningRegistrationType === HappeningRegistrationType.GroupRegistration && (
                    <HappeningField
                        title={strings.membersPerGroup}
                        value={
                            strings.formatString(
                                strings.groupSizeLabel,
                                minPeoplePerGroup,
                                maxPeoplePerGroup > 0 ? maxPeoplePerGroup : strings.unlimited,
                            ) as string
                        }
                    />
                )}
                <HappeningField title={strings.cost} list value={prices} />

                <HappeningField
                    title={strings.typeOfActivity}
                    value={happeningRegistrationTypeToString(happeningRegistrationType)}
                />
            </div>

            <div className={styles.container}>
                {isInternal ? null : (
                    <HappeningField
                        title={strings.happeningTypes}
                        value={
                            tags
                                .filter((x) => x.type === TagType.HappeningType)
                                .map((tag) => tag.name)
                                .join(", ") ?? strings.unknown
                        }
                    />
                )}
                <HappeningField title={strings.happeningGroup} value={happeningGroup?.detailedName || "-"} />
            </div>

            <Location location={location} type={locationType} />
        </div>
    );
}
