import { useMyHappeningsOverview } from "../../hooks/HappeningsHooks";
import HappeningsTable from "./HappeningsTable";

export default function MyHappeningsContainer() {
    const [happeningsState, getMyHappenings] = useMyHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getMyHappenings}
            error={happeningsState.error}
        />
    );
}
