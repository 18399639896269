import React from "react";
import { Route } from "react-router-dom";
import Bouncer from "../authentication/Bouncer";
import { canAssignApplications, canCreateApplications, canViewApplications } from "../authentication/Permissions";
import strings from "../localization/strings";
import { NavigationTab } from "../types/NavigationTab";
import useTabRoute from "./UseTabRoute";
import AllApplicationsContainer from "../components/ApplicationsPages/AllApplicationsContainer";
import MyApplicationsContainer from "../components/ApplicationsPages/MyApplicationsContainer";
import ApplicationFormContainer from "../components/Forms/ApplicationForm/ApplicationFormContainer";
import { EditApplicationContextProvider } from "../contexts/EditApplicationContext";
import CreateApplicationContainer from "../components/Forms/ApplicationForm/CreateApplicationContainer";
import { createApplicationLink } from "../utils.ts/Urls";
import { AttachmentsContextProvider } from "../contexts/AttachmentsContext";

const tabs: NavigationTab[] = [
    {
        pathname: "all",
        label: strings.allApplications,
        element: <AllApplicationsContainer />,
        permissions: [canAssignApplications],
    },
    { pathname: "my", label: strings.myApplications, element: <MyApplicationsContainer /> },
];

const useApplicationsRoute = () => (
    <>
        <Route
            element={
                <Bouncer
                    permissions={[canViewApplications]}
                    element={
                        <EditApplicationContextProvider>
                            <AttachmentsContextProvider>
                                <ApplicationFormContainer />
                            </AttachmentsContextProvider>
                        </EditApplicationContextProvider>
                    }
                />
            }
            path="applications/:applicationId"
        />

        <Route
            element={
                <Bouncer
                    permissions={[canCreateApplications]}
                    element={
                        <AttachmentsContextProvider>
                            <CreateApplicationContainer />
                        </AttachmentsContextProvider>
                    }
                />
            }
            path={createApplicationLink}
        />

        {useTabRoute("applications", tabs, [canViewApplications])}
    </>
);

export default useApplicationsRoute;
