import strings from "../localization/strings";
import { HappeningRegistrationType } from "../openapi/backend";

export function happeningRegistrationTypeToString(type: HappeningRegistrationType): string {
    switch (type) {
        case HappeningRegistrationType.SoloRegistration:
            return strings.register;
        case HappeningRegistrationType.NoRegistration:
            return strings.walkIn;
        case HappeningRegistrationType.GroupRegistration:
            return strings.teamHappening;
        default:
            return strings.unknown;
    }
}
