/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Avoid unreachable code caused by an improper return.
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    ApplicationOrderBy,
    ApplicationOrderByFromJSON,
    ApplicationOrderByToJSON,
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusToJSON,
    Apply200Response,
    Apply200ResponseFromJSON,
    Apply200ResponseToJSON,
    ApplyRequest,
    ApplyRequestFromJSON,
    ApplyRequestToJSON,
    ArrangementApplicationListOutput,
    ArrangementApplicationListOutputFromJSON,
    ArrangementApplicationListOutputToJSON,
    ArrangementApplicationListOutputPaginatedViewModel,
    ArrangementApplicationListOutputPaginatedViewModelFromJSON,
    ArrangementApplicationListOutputPaginatedViewModelToJSON,
    ArrangementApplicationOperation,
    ArrangementApplicationOperationFromJSON,
    ArrangementApplicationOperationToJSON,
    ArrangementListOutput,
    ArrangementListOutputFromJSON,
    ArrangementListOutputToJSON,
    ArrangementOutput,
    ArrangementOutputFromJSON,
    ArrangementOutputToJSON,
    ArrangementType,
    ArrangementTypeFromJSON,
    ArrangementTypeToJSON,
    PersonalisedArrangements,
    PersonalisedArrangementsFromJSON,
    PersonalisedArrangementsToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from "../models";

export interface ApplyOperationRequest {
    id: string;
    version: string;
    applyRequest?: ApplyRequest;
}

export interface GetApplicationRequest {
    id: string;
    version: string;
}

export interface GetApplicationsRequest {
    version: string;
    query?: string;
    municipalities?: Array<string>;
    reviewers?: Array<string>;
    statuses?: Array<ApplicationStatus>;
    fromDate?: Date;
    toDate?: Date;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    orderBy?: ApplicationOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
}

export interface GetArrangementRequest {
    id: string;
    version: string;
}

export interface GetArrangementsRequest {
    version: string;
    flat?: boolean;
    authorizedOnly?: boolean;
    type?: ArrangementType;
}

export interface GetMyApplicationsRequest {
    version: string;
}

export interface GetMyArrangementsRequest {
    version: string;
    flat?: boolean;
    authorizedOnly?: boolean;
    type?: ArrangementType;
}

export interface GetReviewApplicationsRequest {
    version: string;
    query?: string;
    municipalities?: Array<string>;
    reviewers?: Array<string>;
    statuses?: Array<ApplicationStatus>;
    fromDate?: Date;
    toDate?: Date;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    orderBy?: ApplicationOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
}

export interface PatchApplicationRequest {
    id: string;
    version: string;
    arrangementApplicationOperation?: Array<ArrangementApplicationOperation>;
}

/**
 * no description
 */
export class ArrangementsApi extends runtime.BaseAPI {
    /**
     */
    async applyRaw(requestParameters: ApplyOperationRequest): Promise<runtime.ApiResponse<Apply200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling apply.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling apply.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Arrangements/{id}/applications`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: ApplyRequestToJSON(requestParameters.applyRequest),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Apply200ResponseFromJSON(jsonValue));
    }
    /**
     */
    async apply(requestParameters: ApplyOperationRequest): Promise<Apply200Response> {
        const response = await this.applyRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getApplicationRaw(requestParameters: GetApplicationRequest): Promise<runtime.ApiResponse<Apply200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getApplication.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getApplication.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Apply200ResponseFromJSON(jsonValue));
    }
    /**
     */
    async getApplication(requestParameters: GetApplicationRequest): Promise<Apply200Response> {
        const response = await this.getApplicationRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getApplicationsRaw(
        requestParameters: GetApplicationsRequest,
    ): Promise<runtime.ApiResponse<ArrangementApplicationListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getApplications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.municipalities && requestParameters.municipalities.length > 0) {
            queryParameters["Municipalities"] = requestParameters.municipalities;
        }
        if (requestParameters.reviewers && requestParameters.reviewers.length > 0) {
            queryParameters["Reviewers"] = requestParameters.reviewers;
        }
        if (requestParameters.statuses && requestParameters.statuses.length > 0) {
            queryParameters["Statuses"] = requestParameters.statuses;
        }
        if (requestParameters.fromDate !== undefined) {
            queryParameters["FromDate"] = (requestParameters.fromDate as any).toUTCString();
        }
        if (requestParameters.toDate !== undefined) {
            queryParameters["ToDate"] = (requestParameters.toDate as any).toUTCString();
        }
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements/applications`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ArrangementApplicationListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getApplications(
        requestParameters: GetApplicationsRequest,
    ): Promise<ArrangementApplicationListOutputPaginatedViewModel> {
        const response = await this.getApplicationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getArrangementRaw(requestParameters: GetArrangementRequest): Promise<runtime.ApiResponse<ArrangementOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getArrangement.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getArrangement.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ArrangementOutputFromJSON(jsonValue));
    }
    /**
     */
    async getArrangement(requestParameters: GetArrangementRequest): Promise<ArrangementOutput> {
        const response = await this.getArrangementRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getArrangementsRaw(
        requestParameters: GetArrangementsRequest,
    ): Promise<runtime.ApiResponse<Array<ArrangementListOutput>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getArrangements.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.flat !== undefined) {
            queryParameters["Flat"] = requestParameters.flat;
        }
        if (requestParameters.authorizedOnly !== undefined) {
            queryParameters["AuthorizedOnly"] = requestParameters.authorizedOnly;
        }
        if (requestParameters.type !== undefined) {
            queryParameters["Type"] = requestParameters.type;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArrangementListOutputFromJSON));
    }
    /**
     */
    async getArrangements(requestParameters: GetArrangementsRequest): Promise<Array<ArrangementListOutput>> {
        const response = await this.getArrangementsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyApplicationsRaw(
        requestParameters: GetMyApplicationsRequest,
    ): Promise<runtime.ApiResponse<Array<ArrangementApplicationListOutput>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyApplications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements/applications/my`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            jsonValue.map(ArrangementApplicationListOutputFromJSON),
        );
    }
    /**
     */
    async getMyApplications(
        requestParameters: GetMyApplicationsRequest,
    ): Promise<Array<ArrangementApplicationListOutput>> {
        const response = await this.getMyApplicationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyArrangementsRaw(
        requestParameters: GetMyArrangementsRequest,
    ): Promise<runtime.ApiResponse<PersonalisedArrangements>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyArrangements.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.flat !== undefined) {
            queryParameters["Flat"] = requestParameters.flat;
        }
        if (requestParameters.authorizedOnly !== undefined) {
            queryParameters["AuthorizedOnly"] = requestParameters.authorizedOnly;
        }
        if (requestParameters.type !== undefined) {
            queryParameters["Type"] = requestParameters.type;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements/my`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalisedArrangementsFromJSON(jsonValue));
    }
    /**
     */
    async getMyArrangements(requestParameters: GetMyArrangementsRequest): Promise<PersonalisedArrangements> {
        const response = await this.getMyArrangementsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getReviewApplicationsRaw(
        requestParameters: GetReviewApplicationsRequest,
    ): Promise<runtime.ApiResponse<ArrangementApplicationListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getReviewApplications.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.municipalities && requestParameters.municipalities.length > 0) {
            queryParameters["Municipalities"] = requestParameters.municipalities;
        }
        if (requestParameters.reviewers && requestParameters.reviewers.length > 0) {
            queryParameters["Reviewers"] = requestParameters.reviewers;
        }
        if (requestParameters.statuses && requestParameters.statuses.length > 0) {
            queryParameters["Statuses"] = requestParameters.statuses;
        }
        if (requestParameters.fromDate !== undefined) {
            queryParameters["FromDate"] = (requestParameters.fromDate as any).toUTCString();
        }
        if (requestParameters.toDate !== undefined) {
            queryParameters["ToDate"] = (requestParameters.toDate as any).toUTCString();
        }
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Arrangements/applications/reviews`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            ArrangementApplicationListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getReviewApplications(
        requestParameters: GetReviewApplicationsRequest,
    ): Promise<ArrangementApplicationListOutputPaginatedViewModel> {
        const response = await this.getReviewApplicationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async patchApplicationRaw(
        requestParameters: PatchApplicationRequest,
    ): Promise<runtime.ApiResponse<Apply200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling patchApplication.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling patchApplication.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Arrangements/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.arrangementApplicationOperation?.map(ArrangementApplicationOperationToJSON),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Apply200ResponseFromJSON(jsonValue));
    }
    /**
     */
    async patchApplication(requestParameters: PatchApplicationRequest): Promise<Apply200Response> {
        const response = await this.patchApplicationRaw(requestParameters);
        return await response.value();
    }
}
