import { useCallback } from "react";
import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import strings from "../../../../localization/strings";
import { QuestionType } from "../../../../openapi/backend";
import { QuestionFieldValue } from "../../../../types/FormInputTypes";
import MultiField, { FieldProps } from "../../Inputs/MultiField/MultiField";
import MultiTypeQuestionField from "../../Inputs/MultiTypeQuestionField/MultiTypeQuestionField";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

export interface Props<TFieldValues extends FieldValues, TName extends FieldName<TFieldValues, QuestionFieldValue[]>> {
    label?: string;
    name: TName;
    control: Control<TFieldValues, object>;
    errors?: FieldError;
    readOnly?: boolean;
}

const defaultValue = {
    isRequired: true,
    isMultipleChoice: true,
    type: QuestionType.Numeric,
} as Partial<QuestionFieldValue>;

function isEmptyValue(value: Partial<QuestionFieldValue> | undefined) {
    return !value?.title;
}

export default function FormQuestionBuilderField<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, QuestionFieldValue[]>,
>({ label, name, control, errors, readOnly }: Props<TFieldValues, TName>) {
    const Field = useCallback(
        ({ index, fieldValue, onChange: fieldOnChange }: FieldProps<Partial<QuestionFieldValue>>) => {
            const onChangeManipulator = (value: Partial<QuestionFieldValue>) => {
                const isMultipleChoice = value.type === QuestionType.MultipleChoice;
                return fieldOnChange({
                    ...value,
                    isMultipleChoice,
                    options: isMultipleChoice ? value.options : undefined,
                });
            };
            return (
                <MultiTypeQuestionField
                    name={`${name}.${index}`}
                    value={fieldValue ?? {}}
                    onChange={onChangeManipulator}
                    readOnly={readOnly}
                />
            );
        },
        [name, readOnly],
    );

    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <MultiField
                        Field={Field}
                        onChange={(questions) => onChange(questions.filter((x) => !!x.title))}
                        value={value}
                        label={strings.questionNumber}
                        defaultValue={defaultValue}
                        compensateForLabel
                        manipulationType="append"
                        rearrangeable
                        readOnly={readOnly}
                        isEmptyValue={isEmptyValue}
                    />
                )}
            />
        </FormFieldContainer>
    );
}
