import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
    SendNotificationInput,
    useGeneralNotificationAudienceCount,
    useSendGeneralNotification,
} from "../../hooks/NotificationHooks";
import { RequestState } from "../../hooks/UseApiCall";
import strings from "../../localization/strings";
import { Gender, NotificationChannel, NotificationGroup } from "../../openapi/backend";
import { getStringForNotificationType } from "../../utils.ts/GetNotificationType";
import Form from "../core/Form/Form/Form";
import FormField from "../core/Form/FormField/FormField";
import { FormStringSelect } from "../core/Form/FormSelect/FormSelect";
import styles from "./Notifications.module.scss";
import FormFieldMultiline from "../core/Form/FormFieldMultiline/FormFieldMultiline";
import { getStringForNotificationGroup } from "../../utils.ts/GetNotificationGroup";
import FormMultiSelectField from "../core/Form/FormMultiSelectField/FormMultiSelectField";
import { getStringForNotificationGender } from "../../utils.ts/GetNotificationGender";
import { TagsContext } from "../../contexts/TagsContext";
import SearchDataListInput from "../core/Inputs/SearchDataListInput/SearchDataListInput";
import FormMultiDataListInput from "../core/Form/FormMultiDataListInput/FormMultiDataListInput";
import { showError } from "../../utils.ts/NotificationSentStatusToast";
import { toastWaitingForFeedback } from "../../utils.ts/Toaster";

export default function Notification() {
    const {
        register,
        handleSubmit,
        control,
        getValues,
        watch,
        resetField,
        formState: { errors },
    } = useForm<SendNotificationInput>();

    const generalFilterOptionsChannel = watch("generalFilterOptionsChannel");
    const generalFilterOptionsMinAge = watch("generalFilterOptionsMinAge");
    const generalFilterOptionsMaxAge = watch("generalFilterOptionsMaxAge");
    const generalFilterOptionsAudience = watch("generalFilterOptionsAudience");
    const generalFilterOptionsGenders = watch("generalFilterOptionsGenders");
    const interests = watch("interests");
    const areas = watch("areas");
    const partnerGroups = watch("partnerGroups");
    const happeningGroups = watch("happeningGroups");
    const title = watch("title");
    const body = watch("body");

    const [{ state, value: notificationResponse, error }, sendNotification, resetNotificationState] =
        useSendGeneralNotification();
    const [
        { value: valueGeneralNotificationAudienceCount, state: stateGeneralNotificationAudienceCount },
        getGeneralNotificationAudienceCount,
    ] = useGeneralNotificationAudienceCount();
    const tags = useContext(TagsContext).activeTags;
    const [ageError, setAgeError] = useState(false);

    const getAudienceText = (state: RequestState, count?: number): string => {
        if (state === RequestState.DONE && count) {
            return strings.formatString(strings.audienceCountText, count) as string;
        } else if (state === RequestState.LOADING) {
            return strings.loading;
        } else if (state === RequestState.ERROR) {
            return strings.audienceRequestFailed;
        } else {
            return strings.audienceHint;
        }
    };

    useEffect(() => {
        if (generalFilterOptionsMinAge && generalFilterOptionsMaxAge) {
            if (generalFilterOptionsMinAge > generalFilterOptionsMaxAge) {
                setAgeError(true);
            } else {
                setAgeError(false);
            }
        } else {
            setAgeError(false);
        }
    }, [generalFilterOptionsMinAge, generalFilterOptionsMaxAge]);

    useEffect(() => {
        resetField("generalFilterOptionsMinAge");
        resetField("generalFilterOptionsMaxAge");
        resetField("generalFilterOptionsGenders");
        resetField("interests");
        resetField("areas");
        resetField("partnerGroups");
        resetField("happeningGroups");
    }, [generalFilterOptionsAudience, resetField]);

    useEffect(() => {
        if (generalFilterOptionsAudience) {
            const areasTags = areas || [];

            getGeneralNotificationAudienceCount({
                channel: generalFilterOptionsChannel,
                audience: generalFilterOptionsAudience,
                genders: generalFilterOptionsGenders,
                minAge: generalFilterOptionsMinAge,
                maxAge: generalFilterOptionsMaxAge,
                partnerTags: partnerGroups || [],
                happeningTypeTags: interests || [],
                happeningGroupTags: happeningGroups ? happeningGroups : [],
                locationTags: areasTags.map((tag) => tag.id) || [],
            });
        }
    }, [
        generalFilterOptionsChannel,
        generalFilterOptionsAudience,
        generalFilterOptionsMinAge,
        generalFilterOptionsMaxAge,
        generalFilterOptionsGenders,
        interests,
        areas,
        partnerGroups,
        happeningGroups,
        getGeneralNotificationAudienceCount,
    ]);

    useEffect(() => {
        if (state === RequestState.DONE && notificationResponse) {
            toastWaitingForFeedback(
                strings.formatString(
                    strings.waitingForSendNotificationMessage,
                    getStringForNotificationType(getValues("generalFilterOptionsChannel")),
                    getValues("title"),
                ) as string,
                notificationResponse.feedbackId,
            );
            resetField("title");
            resetField("body");
            resetNotificationState();
        }
    }, [state, getValues, resetField, notificationResponse, resetNotificationState]);

    useEffect(() => {
        if (state === RequestState.ERROR && title && body) {
            showError(getValues("generalFilterOptionsChannel"));
        }
    }, [state, title, body, getValues]);

    return (
        <div
            className={styles.notificationFormContainer}
            onKeyPress={(e) => {
                if ((e.target as Element).tagName.toUpperCase() === "TEXTAREA" || e.key !== "Enter") {
                    return;
                }
                e.preventDefault();
            }}
        >
            <Form
                submitText={strings.sendNotification}
                onSubmit={sendNotification}
                handleSubmit={handleSubmit}
                waitingForSubmit={state === RequestState.LOADING}
                error={error}
                defaultErrorMesssage={strings.sentError}
            >
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>{strings.typeOfNotification}</div>

                    <div className={`${styles.dropdownContainer} ${styles.halfSection}`}>
                        <FormStringSelect
                            name={"generalFilterOptionsChannel"}
                            defaultValue={undefined}
                            options={(
                                Object.values(NotificationChannel) as Array<keyof typeof NotificationChannel>
                            ).map((t) => ({
                                label: getStringForNotificationType(t),
                                value: t,
                            }))}
                            label={" "}
                            placeholder={" "}
                            control={control}
                            errors={errors.generalFilterOptionsChannel}
                            required
                        />
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>{strings.recipient}</div>
                    <div className={`${styles.dropdownContainer} ${styles.halfSection}`}>
                        <FormStringSelect
                            name={"generalFilterOptionsAudience"}
                            defaultValue={undefined}
                            placeholder={" "}
                            options={(Object.values(NotificationGroup) as Array<keyof typeof NotificationGroup>).map(
                                (t) => ({
                                    label: getStringForNotificationGroup(t),
                                    value: t,
                                }),
                            )}
                            label={""}
                            control={control}
                            errors={errors.generalFilterOptionsAudience}
                            required
                        />
                    </div>

                    <div className={styles.halfSection}>
                        <div className={styles.countReceivers}>
                            <div className={styles.countReceiversText}>
                                {getAudienceText(
                                    stateGeneralNotificationAudienceCount,
                                    valueGeneralNotificationAudienceCount,
                                )}
                            </div>
                        </div>
                    </div>

                    {generalFilterOptionsAudience === NotificationGroup.Participants && (
                        <div className={styles.ageContainer}>
                            <FormField
                                label={strings.age}
                                name={"generalFilterOptionsMinAge"}
                                type={"number"}
                                register={register}
                                errors={
                                    ageError
                                        ? { type: "pattern", message: strings.ageError }
                                        : errors.generalFilterOptionsMinAge
                                }
                                placeholder={""}
                                containerClassName={styles.ageInputContainer}
                                className={styles.ageInputField}
                            />
                            <div className={styles.ageText}>{strings.until}</div>
                            <FormField
                                name={"generalFilterOptionsMaxAge"}
                                type={"number"}
                                register={register}
                                errors={
                                    ageError
                                        ? { type: "pattern", message: strings.ageError }
                                        : errors.generalFilterOptionsMaxAge
                                }
                                placeholder={""}
                                containerClassName={styles.ageInputContainer}
                                className={styles.ageInputField}
                            />
                            <div className={styles.ageText}>{strings.years.toLowerCase()}</div>
                        </div>
                    )}

                    {generalFilterOptionsAudience === NotificationGroup.Participants && (
                        <div className={styles.halfSection}>
                            <FormMultiSelectField
                                name={"generalFilterOptionsGenders"}
                                label={strings.gender}
                                value={undefined}
                                placeholder={
                                    generalFilterOptionsGenders && generalFilterOptionsGenders?.length > 0
                                        ? strings.makeChoice
                                        : strings.allGenders
                                }
                                options={(Object.values(Gender) as Array<keyof typeof Gender>).map((t) => ({
                                    label: getStringForNotificationGender(t),
                                    value: t,
                                }))}
                                control={control}
                                errors={errors.generalFilterOptionsGenders}
                            />
                        </div>
                    )}

                    {generalFilterOptionsAudience === NotificationGroup.Participants && (
                        <div className={styles.halfSection}>
                            <FormMultiSelectField
                                name={"interests"}
                                label={strings.interests}
                                placeholder={
                                    interests && interests?.length > 0 ? strings.makeChoice : strings.allInterests
                                }
                                options={tags?.happeningTypes.map((t) => ({ label: t.name, value: t.id })) || []}
                                control={control}
                                errors={errors.interests}
                            />
                        </div>
                    )}

                    {generalFilterOptionsAudience === NotificationGroup.Participants && (
                        <SearchDataListInput
                            list="areaSearchNotifications"
                            name={"areas"}
                            control={control}
                            label={strings.restrictNotificationOnPostalCode}
                        />
                    )}

                    {generalFilterOptionsAudience === NotificationGroup.Partners && (
                        <div className={styles.halfSection}>
                            <FormMultiDataListInput
                                name={"partnerGroups"}
                                label={strings.linkPartners}
                                errors={errors.partnerGroups}
                                options={tags?.partners.map((t) => ({ label: t.name, value: t.id })) || []}
                                control={control}
                                list="partnerGroupsNotifications"
                            />
                        </div>
                    )}

                    {(generalFilterOptionsAudience === NotificationGroup.OrganizationAccounts ||
                        generalFilterOptionsAudience === NotificationGroup.Editors) && (
                        <div className={styles.halfSection}>
                            <FormMultiSelectField
                                name={"happeningGroups"}
                                options={tags?.happeningGroups.map((t) => ({ label: t.name, value: t.id })) || []}
                                label={strings.happeningGroup}
                                placeholder={
                                    happeningGroups && happeningGroups?.length > 0
                                        ? strings.makeChoice
                                        : strings.allHappeningGroups
                                }
                                control={control}
                                errors={errors.happeningGroups}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.section}>
                    <div className={styles.sectionTitle}>{strings.message}</div>

                    <FormFieldMultiline
                        rows={1}
                        name={"title"}
                        register={register}
                        label={strings.subject}
                        placeholder={""}
                        required
                        errors={errors.title}
                        maxLength={78}
                        canResize={false}
                    />
                    <FormFieldMultiline
                        rows={5}
                        name={"body"}
                        register={register}
                        label={strings.message}
                        placeholder={""}
                        required
                        errors={errors.body}
                        maxLength={
                            generalFilterOptionsChannel === NotificationChannel.PushNotification ? 178 : undefined
                        }
                    />
                </div>
            </Form>
        </div>
    );
}
