import cx from "classnames";
import { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";

import strings from "../../../localization/strings";
import {
    ArrangementApplicationInput,
    UserListOutput,
    ApplicationEventOutput,
    ApplicationStatus,
    ApplyRequest,
    FormType,
    FileViewModel,
} from "../../../openapi/backend";
import Form from "../../core/Form/Form/Form";
import FormField from "../../core/Form/FormField/FormField";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import styles from "./ApplicationForm.module.scss";
import { PatchFunction } from "./SharedApplicationTypes";
import { CrossButton } from "../../core/CrossButton/CrossButton";
import CancelModal from "../Happening/CancelModal";
import {
    validationSchema,
    validationSchemaKeiComputer,
    validationSchemaKeiGeneric,
    validationSchemaKeiParticipas,
    validationSchemaKeiSchoolTrip,
} from "./ApplicationValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import ApplicationEventHistory from "../../core/ApplicationEventHistory/ApplicationEventHistory";
import SecondaryButtons from "./SecondaryButtons";
import ApplicationHeader, { HeaderApplication } from "./ApplicationFormHeader";
import SupplementField from "./SupplementField";
import GenericApplicationFields from "./GenericApplicationFields";
import ScApplicationFields from "./ScApplicationFields";
import KeiApplicationFields from "./KeiApplicationFields";
import { hasValues } from "../../../utils.ts/Array";
import AttachmentsField from "./AttachmentsField";
import { AttachmentsContext } from "../../../contexts/AttachmentsContext";

type FormApplication = Partial<ApplyRequest> & Pick<ApplyRequest, "type"> & { fileId?: number; file?: FileViewModel };

type Props = {
    onDismiss: () => void;
    application: FormApplication;
    intermediaries: UserListOutput[] | undefined;
    onPatch: PatchFunction;
    isLoading: boolean;
    header: HeaderApplication;
    status?: ApplicationStatus;
    events?: ApplicationEventOutput[];
    requestError?: Response;
};

const getValidationSchema = (application: Pick<ApplyRequest, "type">) => {
    switch (application.type) {
        case FormType.KeiComputer:
            return validationSchemaKeiComputer;
        case FormType.KeiParticipas:
            return validationSchemaKeiParticipas;
        case FormType.KeiSchoolTrip:
            return validationSchemaKeiSchoolTrip;
        case FormType.Jsc:
        case FormType.Vsc:
            return validationSchema;
        default:
            return validationSchemaKeiGeneric;
    }
};

export default function ApplicationForm({
    onDismiss,
    application,
    intermediaries,
    onPatch,
    isLoading,
    header,
    status,
    events,
    requestError,
}: Props) {
    const [isConfirmCancelOpen, setConfirmCancelOpen] = useState(false);
    const onCloseConfirmCancel = useCallback(() => setConfirmCancelOpen(false), []);
    const onOpenConfirmCancel = useCallback(() => setConfirmCancelOpen(true), []);
    const { file, selectedFile } = useContext(AttachmentsContext);

    const {
        register,
        formState: { errors },
        watch,
        control,
        setValue,
        handleSubmit,
    } = useForm<ApplyRequest>({
        defaultValues: application,
        resolver: yupResolver(getValidationSchema(application)),
    });

    const isSc = [FormType.Jsc, FormType.Vsc].includes(application.type);
    const isKei = [FormType.KeiComputer, FormType.KeiParticipas, FormType.KeiSchoolTrip].includes(application.type);
    const hasFileUpload = [FormType.KeiComputer, FormType.KeiSchoolTrip].includes(application.type);

    const onSave = useCallback(
        (data: ArrangementApplicationInput) => {
            if (!hasFileUpload || file || selectedFile) {
                onPatch(data);
            }
        },
        [file, selectedFile, onPatch, hasFileUpload],
    );

    if (!isKei && !isSc) {
        return <div>{strings.formatString(strings.applicationTypeUnsupported, application.type)}</div>;
    }

    return (
        <FormPageLayout
            title={strings.applicationDetailTitle}
            className={styles.container}
            background="white"
            actionButtons={
                <CrossButton className={styles.closeButton} onClick={onOpenConfirmCancel} alt={strings.sluiten} />
            }
        >
            <ApplicationHeader application={header} intermediaries={intermediaries} watch={watch} setValue={setValue} />
            <Form
                onSubmit={onSave}
                handleSubmit={handleSubmit}
                onDismiss={onOpenConfirmCancel}
                className={cx(styles.form, styles.dividerBottom)}
                error={requestError}
                waitingForSubmit={isLoading}
                secondaryButtons={
                    <SecondaryButtons
                        onPatch={onPatch}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        status={status}
                    />
                }
            >
                <FormField name="type" type="hidden" register={register} required placeholder="" />
                <GenericApplicationFields register={register} errors={errors} control={control} watch={watch} />

                {isSc && (
                    <ScApplicationFields
                        register={register as any}
                        errors={errors}
                        form={application.type}
                        control={control as any}
                        watch={watch as any}
                        setValue={setValue as any}
                    />
                )}

                {isKei && (
                    <KeiApplicationFields
                        register={register as any}
                        errors={errors}
                        form={application.type}
                        control={control as any}
                        watch={watch as any}
                    />
                )}

                <SupplementField register={register} errors={errors} />

                {hasFileUpload && <AttachmentsField register={register as any} />}
            </Form>
            {hasValues(events) && <ApplicationEventHistory className={styles.history} events={events} />}
            <CancelModal isOpen={isConfirmCancelOpen} onDismiss={onCloseConfirmCancel} onSuccess={onDismiss} />
        </FormPageLayout>
    );
}
