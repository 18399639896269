import { useInternalHappeningsOverview } from "../../hooks/HappeningsHooks";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["targetGroups"];

export default function InternalHappeningsContainer() {
    const [happeningsState, getInternalHappenings] = useInternalHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getInternalHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
        />
    );
}
