import { Profile } from "../contexts/ProfileContext";
import { BareHappening } from "../utils.ts/Happening";

export function userIsLinked(happening: BareHappening, user?: Profile) {
    if (!user) {
        return false;
    }

    return (
        (happening.projectLeader && happening.projectLeader.id === user.id) ||
        (happening.contact && happening.contact.id === user.id) ||
        happening.projectMembers.findIndex((member) => member.id === user.id) !== -1 ||
        (happening.projectCoLeaders && happening.projectCoLeaders.findIndex((member) => member.id === user.id) !== -1)
    );
}
