import strings from "../../localization/strings";
import StateLabel from "../core/StateLabel/StateLabel";
import GroupIcon from "../core/AvatarIcon/GroupIcon";
import Table from "../core/Table/Table";
import { ReactNode, useMemo, useCallback, useState, useEffect, useContext } from "react";
import {
    HappeningListItemViewModelPaginatedViewModel,
    HappeningOrderBy,
    HappeningTimeAndDateViewModel,
    HappeningType,
    SortOrder,
    UserSubset,
} from "../../openapi/backend";
import { Column } from "react-table";
import moment from "moment";
import styles from "./HappeningsTable.module.scss";
import ProfileContent from "../core/ProfileContent/ProfileContent";
import { GetHappeningsFun, HappeningFilter } from "../../hooks/HappeningsHooks";
import HappeningFilterBar from "../core/FilterBar/HappeningFilterBar";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import { TagsContext } from "../../contexts/TagsContext";
import { useProjectLeaderOptions } from "../../hooks/UserHooks";
import { UserNamedType } from "../../types/UserNamedType";
import { getName } from "../../utils.ts/GetName";
import {
    DateFilter,
    FilterSpecs,
    NamedOptionsFilter,
    PageNumberFilter,
    StringFilter,
    useFilters,
} from "../../hooks/UseSearchParam";
import { getStateOptions } from "../../utils.ts/StateOptions";
import { RequestState } from "../../hooks/UseApiCall";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import { useNavigate } from "react-router";
import { getHappeningLink } from "../../utils.ts/Urls";
import AccessibilityIcon from "../../assets/accessibility_icon.svg";
import LockIcon from "../../assets/lock_icon.svg";
import ClusterIcon from "../../assets/cluster_icon.svg";
import HiddenIcon from "../../assets/hidden_icon.svg";
import { getLocationTypeString } from "../../utils.ts/GetLocationTypeString";
import { getSimplifiedTargetGroupOptions } from "../../utils.ts/TargetGroupOptions";
import { getRelevantSession } from "../../utils.ts/Happening";

const getDate = (dateTimes: HappeningTimeAndDateViewModel[], minimumDate?: Date, maximumDate?: Date) => {
    const relevantDateTime = getRelevantSession(dateTimes, minimumDate, maximumDate);
    return relevantDateTime ? `${moment(relevantDateTime.sessionStart).format("DD/MM/YYYY")}` : "-";
};

export interface HappeningTableItem {
    Name: ReactNode;
    Id: string;
    Date: ReactNode;
    ProjectLeader: ReactNode;
    State: ReactNode;
    HappeningGroup: ReactNode;
    Location: ReactNode;
    Participants: ReactNode;
}

interface AllHappeningsProps {
    happenings?: HappeningListItemViewModelPaginatedViewModel;
    getHappenings: GetHappeningsFun;
    error?: Response;
    excludeFilters?: HappeningFilterKeys;
}

// Default sort
const defaultOrderBy = HappeningOrderBy.Date;
const defaultSortOrder = SortOrder.Ascending;

const stateOptions = getStateOptions();
const targetGroupOptions = getSimplifiedTargetGroupOptions();

const columns: Column<HappeningTableItem>[] = [
    {
        Header: strings.happening,
        accessor: "Name",
        width: "20%",
    },
    {
        Header: strings.date,
        accessor: "Date",
        width: "10%",
    },
    {
        Header: strings.projectLeader,
        accessor: "ProjectLeader",
    },
    {
        Header: strings.happeningGroup,
        accessor: "HappeningGroup",
        width: "10%",
    },
    {
        Header: strings.location,
        accessor: "Location",
    },
    {
        Header: strings.participants,
        accessor: "Participants",
        width: "15%",
    },
    {
        Header: strings.state,
        accessor: "State",
        width: "15%",
    },
];

export default function HappeningsTable({ happenings, getHappenings, error, excludeFilters }: AllHappeningsProps) {
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [itemsPerPage] = useState(10);
    const navigate = useNavigate();

    const { value: projectLeaders, state: projectLeadersState } = useProjectLeaderOptions();
    const leaders: UserNamedType[] = useMemo(
        () => (projectLeaders ? projectLeaders.map((leader) => ({ ...leader, name: getName(leader) })) : []),
        [projectLeaders],
    );
    const tags = useContext(TagsContext).allTags;

    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            fromDate: new DateFilter(),
            toDate: new DateFilter(),
            groups: new NamedOptionsFilter(tags.happeningGroups.concat(tags.partners)),
            happeningTypes: new NamedOptionsFilter(tags.happeningTypes),
            states: new NamedOptionsFilter(stateOptions),
            projectLeaders: new NamedOptionsFilter(leaders),
            targetGroups: new NamedOptionsFilter(targetGroupOptions),
            page: new PageNumberFilter(),
        }),
        [tags, leaders],
    );
    const [rawFilters, setFilters] = useFilters(filterSpecs);
    const filters = rawFilters as HappeningFilter;

    const onFilterChange = useCallback(
        (filter: HappeningFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const loadHappenings = useCallback(() => {
        // Wait until the project leaders options have been loaded, because the filters are dependent on them.
        // Otherwise, we get multiple getHappenings calls in quick succession which causes a race condition
        // (the last response to come in will be the "winner").
        // If the project leader call fails, start loading regardless.
        const loadingLeadersDone = leaders.length > 0 || projectLeadersState === RequestState.ERROR;

        if (loadingLeadersDone) {
            return getHappenings(orderBy, sortOrder, itemsPerPage, filters);
        }
    }, [getHappenings, leaders, orderBy, sortOrder, itemsPerPage, filters, projectLeadersState]);

    useEffect(() => {
        loadHappenings();
    }, [loadHappenings]);

    const data = useMemo<HappeningTableItem[]>(() => {
        return (
            happenings?.items?.map((happening) => ({
                Name: (
                    <div className={styles.nameContainer}>
                        <div>{happening.title}</div>
                        {happening.isInternal && <img src={LockIcon} alt={strings.internalHappening} />}
                        {happening.metaData.isCluster && <img src={ClusterIcon} alt={strings.happeningPartOfCluster} />}
                        {happening.isAccessible && <img src={AccessibilityIcon} alt={strings.isAccessible} />}
                        {happening.targetGroup === UserSubset.NotVisibleForParticipation && (
                            <img src={HiddenIcon} alt={strings.happeningHiddenForParticipation} />
                        )}
                    </div>
                ),
                Id: happening.id,
                Date:
                    happening.type === HappeningType.PrivateHappening
                        ? "-"
                        : getDate(happening.dateTimes, filters.fromDate, filters.toDate),
                ProjectLeader: happening.shortProjectLeader ? (
                    <div className={styles.projectLeaderContainer}>
                        <ProfileContent profile={happening.shortProjectLeader} />
                    </div>
                ) : (
                    <>-</>
                ),
                State: <StateLabel state={happening.state} />,
                HappeningGroup: happening.happeningGroup ? <GroupIcon group={happening.happeningGroup} /> : <>-</>,
                Location: happening.location?.name ?? getLocationTypeString(happening.locationType),
                Participants: `${happening.currentNumberOfRegistrations}/${
                    happening.maxNumberOfRegistrations === 0 ? "∞" : happening.maxNumberOfRegistrations
                }`,
            })) ?? []
        );
    }, [happenings, filters.fromDate, filters.toDate]);

    const handleSort = useCallback(
        (sortBy) => {
            // We only sort on 1 column so only take the first element in sortBy
            if (sortBy[0]) {
                setOrderBy(sortBy[0].id);
                setSortOrder(sortBy[0].desc ? SortOrder.Descending : SortOrder.Ascending);
            } else {
                // If no sorting is applied, revert back to default sorting
                setOrderBy(defaultOrderBy);
                setSortOrder(defaultSortOrder);
            }
        },
        [setOrderBy, setSortOrder],
    );

    return (
        <div>
            <HappeningFilterBar onChange={onFilterChange} value={filters} exclude={excludeFilters} />

            {error && <ErrorMessage error={error} />}

            {!error && (
                <Table
                    columns={columns}
                    data={data}
                    emptyString={strings.noHappeningsFound}
                    setPage={setPage}
                    paginationMeta={happenings?.meta}
                    onSort={handleSort}
                    onRowClick={(happening: HappeningTableItem) => navigate(getHappeningLink(happening.Id))}
                    forcePage={filters.page}
                />
            )}
        </div>
    );
}
