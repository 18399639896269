import { useHappeningsOverview } from "../../hooks/HappeningsHooks";
import HappeningsTable from "./HappeningsTable";

export default function AllHappeningsContainer() {
    const [happeningsState, getHappenings] = useHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getHappenings}
            error={happeningsState.error}
        />
    );
}
