import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Column } from "react-table";
import { TagsContext } from "../../contexts/TagsContext";
import { ApplicationFilter, GetApplicationsFun } from "../../hooks/ArrangementHooks";
import { ApiCallState, RequestState } from "../../hooks/UseApiCall";
import {
    DateFilter,
    FilterSpecs,
    NamedOptionsFilter,
    PageNumberFilter,
    StringFilter,
    useFilters,
} from "../../hooks/UseSearchParam";
import { useGetIntermediaries } from "../../hooks/UserHooks";
import strings from "../../localization/strings";
import {
    ApplicationOrderBy,
    ArrangementApplicationListOutputPaginatedViewModel,
    SortOrder,
} from "../../openapi/backend";
import { ApplicationFilterKeys } from "../../types/ApplicationFilterKeys";
import { UserNamedType } from "../../types/UserNamedType";
import { getName } from "../../utils.ts/GetName";
import { getApplicationStatusOptions } from "../../utils.ts/StateOptions";
import ApplicationFilterBar from "../core/FilterBar/ApplicationFilterBar";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import Table from "../core/Table/Table";
import ApplicationStatusLabel from "../core/ApplicationStatusLabel/ApplicationStatusLabel";
import ProfileContent from "../core/ProfileContent/ProfileContent";
import moment from "moment";
import { hasValues } from "../../utils.ts/Array";
import { getApplicationLink } from "../../utils.ts/Urls";

export interface ApplicationTableItem {
    Id: string;
    Applicant: string;
    Municipality: string;
    Reviewer: ReactNode;
    Arrangement: string;
    Status: ReactNode;
    CreationDate: string;
}

interface Props {
    excludeFilters?: ApplicationFilterKeys;
    hook: [ApiCallState<ArrangementApplicationListOutputPaginatedViewModel>, GetApplicationsFun, () => void];
}

// Default sort
const defaultOrderBy = ApplicationOrderBy.Default;
const defaultSortOrder = SortOrder.Ascending;

const statusOptions = getApplicationStatusOptions();

const columns: Column<ApplicationTableItem>[] = [
    {
        Header: strings.arrangement,
        accessor: "Arrangement",
        width: "15%",
        disableSortBy: true,
    },
    {
        Header: strings.applicant,
        accessor: "Applicant",
        width: "25%",
    },
    {
        Header: strings.applicationFormCity,
        accessor: "Municipality",
        width: "20%",
    },
    {
        Header: strings.arrangementIntermediary,
        accessor: "Reviewer",
        width: "20%",
    },
    {
        Header: strings.state,
        accessor: "Status",
        width: "10%",
    },
    {
        Header: strings.date,
        accessor: "CreationDate",
        width: "10%",
    },
];

const itemsPerPage = 10;

export default function ApplicationsTable({ excludeFilters, hook }: Props) {
    const [state, getCallback] = hook;
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const navigate = useNavigate();

    const { value: intermediaries, state: intermediariesState } = useGetIntermediaries();
    const intermediaryOptions: UserNamedType[] = useMemo(
        () => (intermediaries ? intermediaries.map((i) => ({ ...i, name: getName(i) })) : []),
        [intermediaries],
    );
    const tags = useContext(TagsContext).allTags;

    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            fromDate: new DateFilter(),
            toDate: new DateFilter(),
            municipalities: new NamedOptionsFilter(tags.municipalities),
            statuses: new NamedOptionsFilter(statusOptions),
            reviewers: new NamedOptionsFilter(intermediaryOptions),
            page: new PageNumberFilter(),
        }),
        [tags, intermediaryOptions],
    );
    const [rawFilters, setFilters] = useFilters(filterSpecs);
    const filters = rawFilters as ApplicationFilter;

    const onFilterChange = useCallback(
        (filter: ApplicationFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const fetch = useCallback(() => {
        // Wait until the intermediary options have been loaded, because the filters are dependent on them.
        // Otherwise, we get multiple get calls in quick succession which causes a race condition
        // (the last response to come in will be the "winner").
        // If the intermediary call fails, start loading regardless.
        const loadingIntermediariesDone = hasValues(intermediaryOptions) || intermediariesState === RequestState.ERROR;

        if (!loadingIntermediariesDone) {
            return;
        }

        return getCallback({ orderBy, sortOrder, itemsPerPage }, filters);
    }, [getCallback, intermediaryOptions, orderBy, sortOrder, filters, intermediariesState]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const data = useMemo<ApplicationTableItem[]>(() => {
        return (
            state.value?.items?.map((item) => ({
                Applicant: getName(item.subject) || "-",
                Id: item.id,
                Municipality: item.subjectMunicipality || "-",
                Arrangement: item.arrangement.name,
                Reviewer: item.reviewer ? (
                    <div>
                        <ProfileContent profile={item.reviewer} />
                    </div>
                ) : (
                    <>-</>
                ),
                Status: <ApplicationStatusLabel status={item.status} />,
                CreationDate: moment(item.createdAt).format("DD/MM/YYYY"),
            })) ?? []
        );
    }, [state.value]);

    const handleSort = useCallback((sortBy) => {
        // We only sort on 1 column so only take the first element in sortBy
        if (sortBy[0]) {
            setOrderBy(sortBy[0].id);
            setSortOrder(sortBy[0].desc ? SortOrder.Descending : SortOrder.Ascending);
        } else {
            // If no sorting is applied, revert back to default sorting
            setOrderBy(defaultOrderBy);
            setSortOrder(defaultSortOrder);
        }
    }, []);

    return (
        <div>
            <ApplicationFilterBar onChange={onFilterChange} value={filters} exclude={excludeFilters} />

            {state.error && <ErrorMessage error={state.error} />}

            {!state.error && (
                <Table
                    columns={columns}
                    data={data}
                    emptyString={strings.noApplicationsFound}
                    setPage={setPage}
                    paginationMeta={state.value?.meta}
                    onSort={handleSort}
                    onRowClick={(item) => navigate(getApplicationLink(item.Id))}
                    forcePage={filters.page}
                />
            )}
        </div>
    );
}
