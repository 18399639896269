import moment from "moment";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import { HappeningFilter } from "../../../hooks/HappeningsHooks";
import strings from "../../../localization/strings";
import { HappeningListItemViewModelPaginatedViewModel, HappeningTimeAndDateViewModel } from "../../../openapi/backend";
import { HappeningFilterKeys } from "../../../types/HappeningFilterKeys";
import { DATE_MAX_VALUE, DATE_MIN_VALUE } from "../../../utils.ts/DateTime";
import GroupIcon from "../AvatarIcon/GroupIcon";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import HappeningFilterBar from "../FilterBar/HappeningFilterBar";
import InlineLoading from "../InlineLoading/InlineLoading";
import Table from "../Table/Table";
import styles from "./HappeningsSearchTable.module.scss";

const getDate = (
    dateTimes: HappeningTimeAndDateViewModel[],
    minimumDate: Date = DATE_MIN_VALUE,
    maximumDate: Date = DATE_MAX_VALUE,
) => {
    const filtered = dateTimes.filter(({ sessionEnd }) => sessionEnd >= minimumDate && sessionEnd < maximumDate);
    if (filtered.length <= 0) {
        return "-";
    }

    // Sort dates first
    const sortedDateTimes = filtered.sort((a, b) => Number(a.sessionStart) - Number(b.sessionStart));
    // If all sessions have already ended, select the last session by default
    let relevantDateTime = sortedDateTimes[sortedDateTimes.length - 1];
    // Try to find the first session that hasn't ended yet
    const now = Date.now();
    for (let i = 0; i < sortedDateTimes.length; i++) {
        if (sortedDateTimes[i].sessionEnd.getTime() > now) {
            relevantDateTime = sortedDateTimes[i];
            break;
        }
    }

    return moment(relevantDateTime.sessionStart).format("dd, DD/MM/YYYY HH:mm");
};

export interface HappeningTableItem {
    Name: string;
    Id: string;
    Date: string;
    Participants: string;
    HappeningGroup: ReactNode;
    HappeningGroupName: string;
}

interface Props {
    happenings?: HappeningListItemViewModelPaginatedViewModel;
    getHappenings: (itemsPerPage: number, filter: HappeningFilter) => void;
    error?: Response;
    onRowClick: (happeningId: string) => void;
    isLoadingAction: boolean;
    participantName: string;
}

const excludedFilters = ["groups", "happeningTypes", "isInternal", "projectLeaders", "states"] as HappeningFilterKeys;

const columns: Column<HappeningTableItem>[] = [
    {
        Header: strings.date,
        accessor: "Date",
        width: "20%",
        maxWidth: 240,
        minWidth: 210,
    },
    {
        Header: strings.happening,
        accessor: "Name",
        width: "70%",
    },
    {
        Header: strings.participantCountShort,
        accessor: "Participants",
        width: "7%",
        maxWidth: 60,
    },
    {
        Header: strings.happeningGroupShort,
        accessor: "HappeningGroup",
        width: "3%",
        maxWidth: 32,
    },
];

const itemsPerPage = 5;

export default function HappeningsSearchTable({
    happenings,
    getHappenings,
    error,
    onRowClick,
    isLoadingAction,
    participantName,
}: Props) {
    const [filters, setFilters] = useState<HappeningFilter>(() => ({ fromDate: moment().startOf("day").toDate() }));
    const shouldShowResults = useMemo(() => filters.query && filters.query.length >= 2, [filters.query]);
    const [selectedItem, setSelectedItem] = useState<HappeningTableItem | undefined>();

    const onCancelAction = useCallback(() => setSelectedItem(undefined), []);

    const onConfirmAction = useCallback(() => {
        if (!selectedItem) {
            return;
        }

        onRowClick(selectedItem.Id);
        setSelectedItem(undefined);
    }, [onRowClick, selectedItem]);

    const onFilterChange = useCallback(
        (filter: HappeningFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const loadHappenings = useCallback(() => {
        if (!shouldShowResults) {
            return;
        }

        return getHappenings(itemsPerPage, filters);
    }, [getHappenings, filters, shouldShowResults]);

    useEffect(() => {
        loadHappenings();
    }, [loadHappenings]);

    const data = useMemo<HappeningTableItem[]>(() => {
        if (!shouldShowResults) {
            return [];
        }

        return (
            happenings?.items?.map((happening) => ({
                Name: happening.title,
                Id: happening.id,
                Date: getDate(happening.dateTimes, filters.fromDate, filters.toDate),
                Participants: `${happening.currentNumberOfRegistrations}/${happening.maxNumberOfRegistrations}`,
                HappeningGroup: happening.happeningGroup ? <GroupIcon group={happening.happeningGroup} /> : <>-</>,
                HappeningGroupName: happening.happeningGroup?.name || "-",
            })) ?? []
        );
    }, [happenings, filters.fromDate, filters.toDate, shouldShowResults]);

    return (
        <div className={styles.container}>
            <div className={styles.description}>
                {strings.formatString(strings.assignToHappeningDescription, participantName) as string}
            </div>
            <div className={styles.tableContainer}>
                <HappeningFilterBar onChange={onFilterChange} value={filters} exclude={excludedFilters} autoFocus />

                {error && <ErrorMessage error={error} />}

                {isLoadingAction && <InlineLoading className={styles.loading} alternative />}

                {!error && !isLoadingAction && (
                    <Table
                        columns={columns}
                        data={data}
                        emptyString={shouldShowResults ? strings.noHappeningsFound : strings.searchHappeningsHint}
                        setPage={setPage}
                        paginationMeta={happenings?.meta}
                        onRowClick={setSelectedItem}
                        forcePage={filters.page}
                        rowSelectable={true}
                        fixedLayout
                    />
                )}
            </div>
            {!!selectedItem && (
                <ConfirmationModal isOpen onCancel={onCancelAction} onConfirm={onConfirmAction}>
                    <div className={styles.modalBody}>
                        {strings.formatString(strings.assingToHappeningConfirmationBody1, participantName)}
                    </div>
                    <br />
                    <div className={styles.happeningHighlight}>
                        <div className={styles.modalBodyBold}>{selectedItem.Name}</div>
                        <div className={styles.modalBodyBold}>
                            {strings.formatString(
                                strings.assingToHappeningConfirmationBody3,
                                selectedItem.HappeningGroupName,
                            )}
                        </div>
                        <div className={styles.modalBodyBold}>{selectedItem.Date}</div>
                    </div>
                </ConfirmationModal>
            )}
        </div>
    );
}
