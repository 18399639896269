import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TagsContext } from "../../../contexts/TagsContext";
import { ApiCallState, ApiFunction, RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { MeasurementInputModel } from "../../../models/IndicatorModels";
import {
    IndicatorDescriptiveOutput,
    LinkTarget,
    MeasurementStatus,
    ProfileShortOutput,
    QuestionType,
} from "../../../openapi/backend";
import { Operation } from "../../../types/Operation";
import { Size } from "../../../types/Size";
import { hasValues } from "../../../utils.ts/Array";
import { formatDate } from "../../../utils.ts/DateTime";
import { toastSuccess } from "../../../utils.ts/Toaster";
import Accordion from "../../core/Accordion/Accordion";
import { CrossButton } from "../../core/CrossButton/CrossButton";
import Form from "../../core/Form/Form/Form";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import FormField from "../../core/Form/FormField/FormField";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import FormParticipantInput from "../../core/Form/FormParticipantInput/FormParticipantInput";
import { FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import ProfileContent from "../../core/ProfileContent/ProfileContent";
import StateLabel from "../../core/StateLabel/StateLabel";
import TextButton from "../../core/TextButton/TextButton";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import CancelModal from "../Happening/CancelModal";
import styles from "./MeasurementForm.module.scss";
import { conceptValidationSchema, validationSchema } from "./MeasurementValidation";
import MemberDataListInput from "../../core/Form/FormMultiDataListInput/MemberDataListInput";
import FormDateTimePicker from "../../core/Form/FormDateTimePicker/FormDateTimePicker";
import FormMultiDataListInput from "../../core/Form/FormMultiDataListInput/FormMultiDataListInput";

interface Props {
    saveCallback: [ApiCallState<any>, ApiFunction<any, [data: MeasurementInputModel]>, () => void];
    defaultValues?: Partial<MeasurementInputModel>;
    indicator: IndicatorDescriptiveOutput;
    pageTitle?: string;
    operation: Operation;
    measurer?: ProfileShortOutput;
    readOnly?: boolean;
}

function prepareForSubmission(data: MeasurementInputModel) {
    // Do any kind of modifications to the data before submitting to the API
    let modifications: Partial<MeasurementInputModel> = {
        answers: data.answers.filter((answer) => !!answer.value),
    };

    return { ...data, ...modifications };
}

const emptyOptions = [] as any[];

const validationResolver = (data: Partial<MeasurementInputModel>, context: any, options: any) => {
    let schema;
    switch (true) {
        case data.status === MeasurementStatus.Concept:
            schema = conceptValidationSchema;
            break;
        default:
            schema = validationSchema;
            break;
    }

    return yupResolver(schema)(data, context, options);
};

function getDefaultValues(
    defaultValues: Partial<MeasurementInputModel> | undefined,
    indicator: IndicatorDescriptiveOutput,
) {
    return {
        participantInput: {
            query: "",
            value: undefined,
        },
        ...defaultValues,
        answers: indicator.questions.map((question) => ({
            questionId: question.id,
            value: defaultValues?.answers?.find((answer) => answer.questionId === question.id)?.value ?? "",
            type: question.type,
        })),
        linkTarget: indicator.linkTarget,
    };
}

export default function MeasurementForm({
    saveCallback,
    defaultValues,
    indicator,
    pageTitle = strings.measurementFormTitleNew,
    operation,
    measurer,
    readOnly,
}: Props) {
    const [cancelFormOpen, setCancelFormOpen] = useState(false);
    const [formVersion, setFormVersion] = useState(1);
    const { partners } = useContext(TagsContext).activeTags;
    const partnerOptions = useMemo(() => partners.map((group) => ({ label: group.name, value: group.id })), [partners]);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        getValues,
        watch,
    } = useForm<MeasurementInputModel>({
        defaultValues: getDefaultValues(defaultValues, indicator),
        resolver: validationResolver as any,
        context: {
            linkTarget: indicator.linkTarget,
            isNotesRequired: !hasValues(indicator.questions) && indicator.includeNotes,
        },
    });

    // entityState is set as the starting value and not the form value because the form value switches back and forth due to the buttons
    const entityState = defaultValues?.status ?? MeasurementStatus.Concept;

    const [{ state, error }, save] = saveCallback;
    const navigate = useNavigate();

    const partnerId = watch("tagTargetId");

    const preparedSave = useCallback<typeof save>((data) => save(prepareForSubmission(data)), [save]);
    const goBack = useCallback(() => navigate(-1), [navigate]);

    useEffect(() => {
        if (state !== RequestState.DONE) {
            return;
        }

        if (
            operation === Operation.CREATE &&
            entityState === MeasurementStatus.Concept &&
            getValues("status") === MeasurementStatus.Open
        ) {
            reset(getDefaultValues(undefined, indicator));
            setFormVersion((prev) => (prev + 1) % 2);
        } else {
            goBack();
        }
        toastSuccess(strings.measurementSaved);
    }, [state, goBack, reset, getValues, entityState, indicator, operation]);

    const onSaveConcept = useCallback(() => {
        setValue("status", MeasurementStatus.Concept);
        handleSubmit(preparedSave as any)();
    }, [setValue, handleSubmit, preparedSave]);

    const onPublish = useCallback(() => {
        if (entityState === MeasurementStatus.Concept) {
            setValue("status", MeasurementStatus.Open);
        }
        handleSubmit(preparedSave as any)();
    }, [preparedSave, handleSubmit, setValue, entityState]);

    const getSecondaryButtons = useMemo(() => {
        switch (true) {
            case entityState === MeasurementStatus.Concept:
                return (
                    <TextButton
                        onClick={onSaveConcept}
                        text={strings.saveConcept}
                        buttonType="alternate"
                        isLoading={state === RequestState.LOADING}
                    />
                );
            default:
                return undefined;
        }
    }, [state, entityState, onSaveConcept]);

    return (
        <FormPageLayout
            title={pageTitle}
            actionButtons={
                <div className={styles.titleActionButtons}>
                    <CrossButton
                        onClick={() => {
                            if (readOnly) {
                                goBack();
                                return;
                            }

                            setCancelFormOpen(true);
                        }}
                        alt={strings.cancel}
                        className={styles.closeButton}
                    />
                </div>
            }
        >
            <Form
                key={formVersion}
                className={styles.form}
                onSubmit={onPublish}
                handleSubmit={handleSubmit}
                secondaryButtons={getSecondaryButtons}
                error={error}
                submitText={entityState === MeasurementStatus.Concept ? strings.submitForApproval : strings.save}
                waitingForSubmit={state === RequestState.LOADING}
                onDismiss={() => setCancelFormOpen(true)}
                readOnly={readOnly}
            >
                <Accordion
                    className={styles.formSection}
                    title={indicator.name}
                    headerTitleClassName={styles.title}
                    collapsible={false}
                    open
                    headerContent={
                        <div className={styles.headerContent}>
                            <StateLabel state={entityState} />
                        </div>
                    }
                >
                    <div className={styles.info}>
                        <div className={styles.description}>{indicator.description}</div>
                        {indicator.happeningTitle && (
                            <div className={styles.happeningTitle}>{indicator.happeningTitle}</div>
                        )}
                        {indicator.endDate && (
                            <div className={styles.endDate}>
                                {strings.formatString(
                                    strings.indicatorEndDate,
                                    formatDate(indicator.endDate, "DD MMMM YYYY"),
                                )}
                            </div>
                        )}
                    </div>
                </Accordion>
                {indicator.linkTarget !== LinkTarget.None && (
                    <Accordion
                        className={styles.formSection}
                        title={strings.formIndicatorFieldLinkTarget}
                        headerTitleClassName={styles.title}
                        collapsible={false}
                        open
                    >
                        {indicator.linkTarget === LinkTarget.Partner && (
                            <FormDataListInput
                                name={"tagTargetId"}
                                options={partnerOptions}
                                label={strings.linkTargetEnumPartner}
                                control={control}
                                defaultValue={partnerId}
                                errors={errors.tagTargetId}
                                required
                                list={"partnerOptions"}
                                readOnly={readOnly}
                                className={styles.spaceBottom}
                            />
                        )}
                        {indicator.linkTarget === LinkTarget.Profile && (
                            <FormParticipantInput
                                name="participantInput"
                                control={control}
                                errors={errors.participantInput?.value}
                                required
                                label={strings.linkTargetEnumProfile}
                                className={styles.spaceBottom}
                                readOnly={readOnly}
                            />
                        )}
                    </Accordion>
                )}
                <Accordion
                    className={styles.formSection}
                    title={strings.formIndicatorHeaderQuestionnaire}
                    headerTitleClassName={styles.title}
                    collapsible={false}
                    open
                    headerContent={
                        measurer && (
                            <div className={cx(styles.headerContent, styles.alignRight)}>
                                <p className={styles.label}>{`${strings.measurer}:`}</p>
                                <ProfileContent profile={measurer} size={Size.SMALL} />
                            </div>
                        )
                    }
                >
                    {hasValues(indicator.questions) &&
                        indicator.questions.map((question, index) =>
                            question.type === QuestionType.MultipleChoice ? (
                                <FormStringSelect
                                    key={question.id}
                                    name={`answers[${index}].value` as any}
                                    options={
                                        question.options?.map((option) => ({ label: option, value: option })) ??
                                        emptyOptions
                                    }
                                    label={question.title}
                                    control={control}
                                    errors={errors.answers?.[index]?.value}
                                    placeholder=" "
                                    required
                                    readOnly={readOnly}
                                />
                            ) : (
                                <FormField
                                    key={question.id}
                                    name={`answers[${index}].value`}
                                    label={question.title}
                                    register={register}
                                    errors={errors.answers?.[index]?.value}
                                    required
                                    placeholder={""}
                                    type="number"
                                    min={0}
                                    readOnly={readOnly}
                                />
                            ),
                        )}
                    {indicator.includeNotes && (
                        <FormFieldMultiline
                            rows={4}
                            name="notes"
                            label={strings.notes}
                            register={register}
                            errors={errors.notes}
                            readOnly={readOnly}
                            required={!hasValues(indicator.questions)}
                        />
                    )}
                </Accordion>

                <Accordion title={strings.linkHeader}>
                    <MemberDataListInput
                        name={"contributors"}
                        label={strings.teamMembersMeasurements}
                        errors={errors.contributors}
                        control={control}
                        list="contributors"
                    />
                </Accordion>
                {(indicator.canAddCollaborators || indicator.canAddContributors) && (
                    <Accordion
                        className={styles.formSection}
                        title={strings.formIndicatorHeaderCollaboration}
                        headerTitleClassName={styles.title}
                        collapsible={false}
                        open
                    >
                        {indicator.canAddContributors && (
                            <MemberDataListInput
                                name={"contributors"}
                                label={strings.measurementContributors}
                                errors={errors.contributors}
                                control={control}
                                list="contributors"
                                readOnly={readOnly}
                            />
                        )}
                        {indicator.canAddCollaborators && (
                            <FormMultiDataListInput
                                name={"collaborators"}
                                label={strings.partners}
                                errors={errors.collaborators}
                                control={control}
                                list="collaborators"
                                options={partnerOptions}
                                readOnly={readOnly}
                            />
                        )}
                    </Accordion>
                )}
                <Accordion title={strings.dateAndTime}>
                    <FormDateTimePicker
                        name={"createdAt"}
                        dateTitle={strings.measurementDate}
                        control={control}
                        required
                        minDate={indicator.startDate}
                        maxDate={indicator.endDate}
                    />
                </Accordion>
            </Form>
            {cancelFormOpen && (
                <CancelModal isOpen={cancelFormOpen} onDismiss={() => setCancelFormOpen(false)} onSuccess={goBack} />
            )}
        </FormPageLayout>
    );
}
