import { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { canOnlySuggestHappenings } from "../../../authentication/Permissions";
import { HappeningCloneContext } from "../../../contexts/HappeningCloneContext";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { TagsContext } from "../../../contexts/TagsContext";
import { HappeningInput, useCreateHappeningCallback } from "../../../hooks/HappeningsHooks";
import { useClusterIdFromSearchParams } from "../../../hooks/RouteHooks";
import { getImagesWithoutQuickfixImages } from "../../../hooks/useMissingImagesQuickfix";
import strings from "../../../localization/strings";
import { HappeningState, HappeningType, HappeningViewModel, TagType } from "../../../openapi/backend";
import { defaultHappening, defaultPrivateHappening } from "../../../utils.ts/Happening";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import HappeningForm from "../Happening/HappeningForm";
import styles from "./HappeningForm.module.scss";
import { getDefaultValuesFromHappening } from "./HappeningUtils";
import { Operation } from "../../../types/Operation";
import { getHappeningLink } from "../../../utils.ts/Urls";

interface Props {
    type: HappeningType;
}

const emptyArray: any[] = [];

export default function CreateHappeningContainer({ type }: Props) {
    const clusterId = useClusterIdFromSearchParams();
    const { profile } = useContext(ProfileContext);
    const { happening: clonedHappening, setHappening: resetClonedHappening } = useContext(HappeningCloneContext);
    const navigate = useNavigate();
    const isPrivateHappening = useMemo(() => type === HappeningType.PrivateHappening, [type]);

    const defaultValues = useMemo<Partial<HappeningInput>>(() => {
        let happeningGroup: string | undefined = undefined;
        if (
            canOnlySuggestHappenings(profile) &&
            profile?.tags.filter((t) => t.type === TagType.Partner && t.isActive).length === 1
        ) {
            // Prefill if user only has 1 choice
            happeningGroup = profile.tags.find((t) => t.isActive && t.type === TagType.Partner)?.id;
        }

        var prefill = {
            ...(isPrivateHappening ? defaultPrivateHappening : defaultHappening),
            cluster: clusterId,
            happeningGroup,
        } as Partial<HappeningInput>;

        if (clonedHappening) {
            var clonedDetails = getDefaultValuesFromHappening(clonedHappening);

            return {
                ...prefill,
                ...clonedDetails,
                extraQuestions: clonedDetails.extraQuestions?.map((question) => ({ ...question, id: undefined })),
                state: HappeningState.Concept,
                displayState: undefined,
            };
        } else {
            return prefill;
        }
    }, [clonedHappening, profile, isPrivateHappening, clusterId]);

    // Reset the cloned happening when the component will unmount
    useEffect(() => {
        return () => {
            if (clonedHappening) {
                resetClonedHappening();
            }
        };
    }, [clonedHappening, resetClonedHappening]);

    const tags = useContext(TagsContext).activeTags;

    const storedImages = useMemo(
        () => getImagesWithoutQuickfixImages(clonedHappening?.images),
        [clonedHappening?.images],
    );

    const onSuccess = useCallback(
        (model: HappeningViewModel) => {
            navigate(getHappeningLink(model.id), { replace: true });
        },
        [navigate],
    );

    if (!profile || !tags) {
        return <InlineLoading />;
    }

    return (
        <HappeningForm
            title={isPrivateHappening ? strings.createPrivateHappeningFormTitle : strings.createHappening}
            user={profile}
            tags={tags}
            onDismiss={() => navigate(-1)}
            onSaved={onSuccess}
            callback={useCreateHappeningCallback}
            defaultValues={defaultValues}
            storedImages={storedImages}
            className={styles.fullPageForm}
            type={type}
            operation={Operation.CREATE}
            registrations={emptyArray}
        />
    );
}
