export const mediaUrl =
    /^(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]{8,11})(&\S+)?/;

export const MAX_TITLE_LENGTH = 43;
export const MAX_EXTRA_QUESTION_LENGTH = 100;

export const MIN_ALLOWED_PARTICIPANTS_VALUE = 1;
export const MIN_ALLOWED_PARTICIPANT_TEAM_SIZE = 2;
export const MIN_PRICE_VALUE = 0.01;

export const MAX_IMAGE_SIZE = 1024 * 1024 * 8;

export const MIN_AGE = 0;
export const MAX_AGE = 99;

export const MIN_PARTICIPANT_AGE = MIN_AGE;
export const MAX_PARTICIPANT_AGE = 120;

// PhoneNumber.cs is leading, keep up to date with that.
export const phoneNumber =
    /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;

export const basicUrl = new RegExp(
    /^(https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(?:\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?$/,
);

export const basicUrlHttps = new RegExp(
    /^https:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(?:\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?$/,
);

export const selectHttps = new RegExp(/^https?:\/\//);

// Source: https://emailregex.com/
export const emailPattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

// Source: mobile-app checkPostalCode
export const postalCodePattern = new RegExp(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i);
// Source: mobile-app checkName
export const namePattern = new RegExp(/([a-zA-ZÀ-ÿ][-,a-z. '’]*[a-zA-ZÀ-ÿ'’.])+$/u);

export const pricePattern = /^\d+(\.\d{0,2})?$/;
