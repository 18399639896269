import { isChiefEditor } from "../authentication/UserMethods";
import strings from "../localization/strings";
import { UserOutput, UserSubset } from "../openapi/backend";
import { SimplifiedTargetGroupOption } from "../types/DropdownOption";
import { SimplifiedTargetGroupSet } from "../types/SimplifiedTargetGroupSet";

export function getTargetGroupOptions(user: UserOutput, current: UserSubset | undefined) {
    if (isChiefEditor(user)) {
        return [
            getLabelForTargetGroup(UserSubset.AllOrganisations),
            getLabelForTargetGroup(UserSubset.InternalOrganisations),
            getLabelForTargetGroup(UserSubset.PartnerOrganisations),
            getLabelForTargetGroup(UserSubset.SelectedOrganisations),
        ];
    }
    if (current !== undefined && current !== UserSubset.SelectedOrganisations) {
        return [getLabelForTargetGroup(current), getLabelForTargetGroup(UserSubset.SelectedOrganisations)];
    }
    return [getLabelForTargetGroup(UserSubset.SelectedOrganisations)];
}

function getLabelForTargetGroup(userSubset: UserSubset) {
    switch (userSubset) {
        case UserSubset.AllOrganisations:
            return { label: strings.allOrganisations, value: UserSubset.AllOrganisations };
        case UserSubset.InternalOrganisations:
            return { label: strings.allInternalOrganisations, value: UserSubset.InternalOrganisations };
        case UserSubset.Participants:
            return { label: strings.participants, value: UserSubset.Participants };
        case UserSubset.PartnerOrganisations:
            return { label: strings.allPartnerOrganisations, value: UserSubset.PartnerOrganisations };
        case UserSubset.SelectedOrganisations:
            return { label: strings.selectOrganisations, value: UserSubset.SelectedOrganisations };
        case UserSubset.NotVisibleForParticipation:
            return { label: strings.notVisibleForParticipation, value: UserSubset.NotVisibleForParticipation };
    }
}

export function getSimplifiedTargetGroupOptions(): SimplifiedTargetGroupOption[] {
    return Object.values(SimplifiedTargetGroupSet).map((value) => ({
        name: value as keyof typeof SimplifiedTargetGroupSet,
        value: SimplifiedTargetGroupSet[value as keyof typeof SimplifiedTargetGroupSet],
    }));
}

export function targetGroupSetToString(target: SimplifiedTargetGroupSet): string {
    return getStringForTargetGroupSet(target);
}

function getStringForTargetGroupSet(target: SimplifiedTargetGroupSet) {
    switch (target) {
        case SimplifiedTargetGroupSet.Participant:
            return strings.simplifiedTargetGroupOptionEnumParticipant;
        case SimplifiedTargetGroupSet.Internal:
            return strings.simplifiedTargetGroupOptionEnumInternal;
        case SimplifiedTargetGroupSet.NotVisible:
            return strings.simplifiedTargetGroupOptionEnumNotVisible;
        default:
            return strings.unknown;
    }
}
