import {
    HappeningRegistrationOutput,
    HappeningRegistrationType,
    HappeningState,
    HappeningTimeAndDateInput,
    HappeningTimeAndDateViewModel,
    HappeningType,
    HappeningViewModel,
    LocationType,
    UserSubset,
} from "../openapi/backend";
import { hasValues } from "./Array";
import { DATE_MAX_VALUE, DATE_MIN_VALUE } from "./DateTime";
import { StatusType, statusToString } from "./GetStatus";
import { HappeningInput, PrivateHappeningInput } from "./HappeningType";
import { getStateOptions } from "./StateOptions";

export type BareHappening = Pick<
    HappeningViewModel,
    | "state"
    | "dateTimes"
    | "projectLeader"
    | "contact"
    | "projectMembers"
    | "displayState"
    | "cluster"
    | "projectCoLeaders"
    | "createdBy"
>;

// Whether a whole happening can be cancelled. Keep in sync with HappeningModel.CanCancel
export const canCancelHappening = (happening: BareHappening) => {
    var isLegalState = happening.state === HappeningState.Open;
    var canCancellAllSessions = happening.dateTimes.every((session) => canCancelSession(session));

    return isLegalState && canCancellAllSessions;
};

/**
 * Whether session can be cancelled. Keep in sync with HappeningTimeAndDate.CanCancel
 * @returns boolean
 */
export const canCancelSession = (session: HappeningTimeAndDateViewModel, hourOffset: number = 0) => {
    var now = new Date();
    var dateOffset = new Date(now.getTime() + 60 * 60 * 1000 * hourOffset);

    return session.sessionStart > dateOffset && !session.cancelled;
};

export function isSameTimeslot(session1?: HappeningTimeAndDateInput, session2?: HappeningTimeAndDateInput) {
    return (
        session1?.sessionStart.getTime() === session2?.sessionStart.getTime() &&
        session1?.sessionEnd.getTime() === session2?.sessionEnd.getTime()
    );
}

export function getRelevantSession(
    sessions: HappeningTimeAndDateViewModel[],
    minimumDate: Date = DATE_MIN_VALUE,
    maximumDate: Date = DATE_MAX_VALUE,
): HappeningTimeAndDateViewModel | undefined {
    const filtered = sessions.filter(({ sessionEnd }) => sessionEnd >= minimumDate && sessionEnd < maximumDate);
    if (filtered.length <= 0) {
        return sessions[sessions.length - 1];
    }

    // If all sessions have already ended, select the last session by default
    let relevantDateTime = filtered[filtered.length - 1];
    // Try to find the first session that hasn't ended yet
    const now = Date.now();
    for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].sessionEnd.getTime() > now) {
            relevantDateTime = filtered[i];
            break;
        }
    }

    return relevantDateTime;
}

/**
 * Retrieves the reassignable state options based on the given registrations.
 *
 * @param registrations - An array of HappeningRegistrationOutput objects.
 * @returns An array of state options that can be chosen.
 */
export function getReassignableStateOptions(
    registrations: HappeningRegistrationOutput[],
    currentState: HappeningState,
) {
    let states = getStateOptions().filter((state) => state.value !== HappeningState.PlannedForPublication);

    if (currentState === HappeningState.Concept) {
        states = states.filter((state) => state.value !== HappeningState.PartnerSuggestion);
    }

    if (currentState === HappeningState.PartnerSuggestion) {
        states = states.filter((state) => state.value !== HappeningState.Concept);
    }

    if (hasValues(registrations)) {
        states = states.filter(
            (state) =>
                state.value !== HappeningState.Concept &&
                state.value !== HappeningState.PartnerSuggestion &&
                state.value !== HappeningState.Rejected,
        );
    }

    return states.map((option) => {
        return {
            label: statusToString(option.name as StatusType),
            value: option.value,
        };
    });
}

export const defaultPrivateHappening: Partial<PrivateHappeningInput> = {
    tags: [],
    videos: [],
    requiresApprovalFromMarketing: false,
    state: HappeningState.Concept,
    restrictAge: false,
    isAccessible: false,
    price: 0,
    tagInputs: [],
    locationType: LocationType.OnLocation,
    targetGroup: UserSubset.Participants,
    durationInMinutes: 30,
    requiresRegistration: true,
    happeningRegistrationType: HappeningRegistrationType.SoloRegistration,
    isProRata: true,
    happeningType: HappeningType.PrivateHappening,
    link: "",
    linkLabel: "",
};

export const defaultHappening: Partial<HappeningInput> = {
    tags: [],
    timeAndDates: [],
    videos: [],
    requiresApprovalFromMarketing: false,
    state: HappeningState.Concept,
    restrictAge: false,
    isAccessible: false,
    requiresRegistration: true,
    tagInputs: [],
    locationType: LocationType.OnLocation,
    extraQuestions: [],
    targetGroup: UserSubset.Participants,
    happeningRegistrationType: HappeningRegistrationType.SoloRegistration,
    isProRata: true,
    happeningType: HappeningType.Happening,
    link: "",
    linkLabel: "",
};
