import styles from "./Header.module.scss";
import ProfileButton from "../ProfileButton/ProfileButton";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import strings from "../../../localization/strings";
import AddIcon from "../../../assets/add_icon.svg";
import { useContext, useMemo } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import {
    canCreateApplications,
    canCreateHappenings,
    canCreateIndicator,
    canSuggestHappenings,
} from "../../../authentication/Permissions";
import { Link, useLocation } from "react-router-dom";
import {
    createApplicationLink,
    createHappeningLink,
    createIndicatorLink,
    createPrivateHappeningLink,
} from "../../../utils.ts/Urls";
import FloatingMenuButton from "../FloatingMenuButton/FloatingMenuButton";
import { LinkButton } from "../TextButton/TextButton";

const links = [
    {
        id: "linkToNewHappening",
        label: strings.createNormalHappening,
        path: createHappeningLink,
    },
    {
        id: "linkToNewPrivateHappening",
        label: strings.createPrivateHappening,
        path: createPrivateHappeningLink,
    },
];

export default function Header() {
    const { profile: user } = useContext(ProfileContext);
    const location = useLocation();

    const options = useMemo(
        () =>
            links.map(({ id, label, path }) => (
                <Link key={id} id={id} to={path}>
                    <label htmlFor={id} className={styles.linkLabel}>
                        {label}
                    </label>
                </Link>
            )),
        [],
    );

    const showNewHappeningButton = useMemo(() => {
        if (
            !location.pathname.startsWith("/activities") ||
            links.map((value) => value.path).includes(location.pathname)
        ) {
            return false; // wrong location
        }

        return canCreateHappenings(user) || canSuggestHappenings(user);
    }, [location.pathname, user]);

    const showNewApplicationButton = useMemo(() => {
        if (
            !location.pathname.startsWith("/applications") ||
            createApplicationLink.toLowerCase() === location.pathname.toLowerCase()
        ) {
            return false; // wrong location
        }

        return canCreateApplications(user);
    }, [location.pathname, user]);
    const showIndicatorsButton = useMemo(() => {
        if (
            !location.pathname.startsWith("/monitoring/indicators") ||
            createIndicatorLink.toLowerCase() === location.pathname.toLowerCase()
        ) {
            return false; // wrong location
        }

        return canCreateIndicator(user);
    }, [location.pathname, user]);

    return (
        <header className={styles.header}>
            <div className={styles.headerLeft}>
                <h1 className={styles.appTitle}>{strings.sbg}</h1>
                {showNewHappeningButton && (
                    <FloatingMenuButton options={options}>
                        <ToolbarButton text={strings.newHappening} />
                    </FloatingMenuButton>
                )}
                {showNewApplicationButton && (
                    <LinkButton
                        text={strings.newApplication}
                        buttonType="toolbar"
                        icon={AddIcon}
                        href={createApplicationLink}
                    />
                )}
                {showIndicatorsButton && (
                    <LinkButton
                        text={strings.newIndicator}
                        buttonType="toolbar"
                        icon={AddIcon}
                        href={createIndicatorLink}
                    />
                )}
            </div>
            <div>
                <ProfileButton />
            </div>
        </header>
    );
}
